import React from "react";
import "./RapellingZipline.css";
import FirstImg from "./img/1.webp";
import SecondImg from "./img/2.webp";
import ThirdImg from "./img/3.webp";
import FourthImg from "./img/4.webp";
import FifthImg from "./img/5.webp";
import SixthImg from "./img/6.webp";
import Slider from "react-slick";
import rapelling_icon from "./img/rapelling_icon2.webp";
import zipline_icon from "./img/zipline_icon.webp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function RapellingZipline() {
  var settings = {
    accessibility: false,
    arrows: false,
    dots: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: false,
    centerPadding: "50px",
    useCSS: true,
    responsive: [
      {
        breakpoint: 4000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <main id="rappelling-and-zipline">
      <h1>
        <span class="accent-red">Rappelling & Zipline</span>
      </h1>

      <div className="top_text">
        <h2>
          Technology Adventure Society gives you the opportunity to feel two
          adventurous activities inside our campus "Rappelling" and "Zipline".
          These will surely thrill you to a level you can't imagine.
        </h2>
      </div>
      <div className="c1">
        <div className="rapelling_content">
          <h3 className="event_heading">Rapelling</h3>
          <p className="rapelling_text">
            Rappelling has become something of an adventure sport in itself
            these days. Down climbing is much more difficult because you can’t
            see the holds beneath you and you also may be tired from climbing
            up.
          </p>
          <p className="rapelling_text">
            Rappelling is inherently dangerous and it is something you need to
            learn in person from a trained professional, and not from the
            internet and{" "}
            <span className="accent-red socname">
              Technology Adventure Society
            </span>{" "}
            gives you this opportunity.
          </p>
        </div>
        <div className="icon">
          <img src={rapelling_icon} alt="" />
        </div>
      </div>
      <div className="c1" id="col_reverse">
        <div className="icon">
          <img src={zipline_icon} alt="" id="zipline_icon" />
        </div>
        <div className="rapelling_content">
          <h3 className="event_heading">Zipline</h3>
          <p className="rapelling_text">
            A zipline is an outdoor recreation sport/adventure sport where a
            freely moving pulley is used to ride from the top to the bottom of
            an inclined cable.
          </p>
          <p className="rapelling_text">
            It is an nature acitivity that allows you to cross large river
            canyons, widest gorges and sometimes very big distances between high
            buildings in urban surroundings, just by using a stainless steel
            cable, pulley, safety belt and force of gravity, makes it freaking
            cool.
          </p>
        </div>
      </div>
      

      <div className="part2">
        <h3 className="event_heading" id="gal">
          <span id="gallery">Gallery</span>
        </h3>
        <div className="slider_trail">
          <Slider {...settings}>
            <div className="slider_content_trail">
              <div className="title">Zipline</div>
              <div className="card_img">
                <img src={FirstImg} alt="image1" />
              </div>
              <div className="caption">From the top of Gymkhana building!</div>
            </div>
            <div className="slider_content_trail">
              <div className="title">Zipline</div>
              <div className="card_img">
                <img src={SecondImg} alt="image2" />
              </div>
              <div className="caption">Sliding on a rope above Gymkhana lake!</div>
            </div>
            <div className="slider_content_trail">
              <div className="title">Zipline</div>
              <div className="card_img">
                <img src={ThirdImg} alt="image3" />
              </div>
              <div className="caption">Till the island between lakes!</div>
            </div>
            <div className="slider_content_trail">
              <div className="title">Rapelling</div>
                <div className="card_img">
                  <img src={FourthImg} alt="image4" />
                </div>
                <div className="caption">At the top of MMM Hall building!</div>
            </div>
            <div className="slider_content_trail">
            <div className="title">Rapelling</div>
                <div className="card_img">
                  <img src={FifthImg} alt="image5" />
                </div>
                <div className="caption">Climbing down along it's tall wall!</div>
            </div>
            <div className="slider_content_trail">
            <div className="title">Rapelling</div>
                <div className="card_img">
                  <img src={SixthImg} alt="image6" />
                </div>
                <div className="caption">Anchor clipped! Knot tightened! READY to RAPEL!</div>
            </div>
          </Slider>
        </div>
      </div>

      <h3 className="event_heading" id="gal">
        <span id="gallery">A glimpse of Rapelling & Zipline</span>
      </h3>

      <div className="video_container">
        <div id="video_div">
          <div class="yvideo">
            <iframe
              class="yvideo1"
              src="https://www.youtube.com/embed/0bKg5Cp4zU4"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>


<div className="safety">
      <h3 className="event_heading" id="gal">
          <span id="gallery">Safety Tips</span>
        </h3>
        <div className="safety_content">
            <p className="safety_para">Once you’re familiar with zipline equipment and understand zipline safety precautions, there are still a few things you can do to optimize your zipline experience. Here are some tips for beginners:</p>
            <ul>
              <li className="safety_point">Wear close-toed shoes. You don’t want your flip-flop to go flying off into the jungle, make sure anything you wear if firmly secured to your person.</li>
              <li className="safety_point">Choose comfortable clothing. You want to make sure your clothes fit the part. Wear gym clothes or otherwise breathable, loose clothing for the day.</li>
              <li className="safety_point">Empty Your Pockets. Again, you don’t want things flying out of your pockets as you rush down the zip line, so avoid bringing expensive gadgets or other items in your pocket for the zip line adventure.</li>
              <li className="safety_point">Don’t Take Selfies. We’re sure you’re dying to post your zip line adventure to Instagram, but play it safe and avoid taking selfies while in flight — we recommend leaving your phone on the ground anyway!</li>
              <li className="safety_point">Wear Sunscreen and Bug Repellent. Zip lines will take you to places you otherwise might not be able to explore, like the depths of the forest. This means you may encounter some bugs and strong rays of sunshine, so lather up the protective sprays.</li>
            </ul> <br />
            <p className="safety_para">Rappelling as an activity benefits massively from a standardized list of actions which can be checked every time to ensure safety. Here are some tips for beginners:</p>
            <ul>
              <li className="safety_point">Build an appropriate (SERENE / ERNEST) anchor for every rappel; Clip to the anchor with a personal tether while building your rappel</li>
              <li className="safety_point">Knot the ends of your rope; Put your rope’s middle mark at the rappel anchor</li>
              <li className="safety_point">Use a friction hitch like a Prusik below the rappel device; Extend your rappel device about 30cm (1 foot) off your harness</li>
              <li className="safety_point">Fully weight the rappel setup before unclipping yourself from the anchor with your personal tether</li>
              <li className="safety_point">Ask yourself if you consciously double checked everything before rappelling.</li>
              <li className="safety_point">Don't forget any of the above points!</li>
            </ul>
        </div>
      </div>

{/* 
      <div className="container">
        {" "}
        <br />
      </div>
      <div className="container" id="g">
        <br />
      </div> */}
    </main>
  );
}
