import React from "react";
import "./Template.css";
import { MdChevronLeft,MdChevronRight} from 'react-icons/md';



const HarKiDun = (props) => {
  const slides = [{image: 'https://user-images.githubusercontent.com/103182581/178455883-0e73c94c-ef6b-49f0-adfb-d13cbc951b76.jpeg',title:"Trek Name A", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178455894-25fd8afd-6359-4451-91f2-8e3d5a528bd1.jpeg' ,title:"Trek Name A", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178455904-013777f4-c848-4adf-ab09-b9a35e52a810.jpeg',title:"Trek Name B", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178455913-db9bdf3f-dae0-4c44-9d07-4832a5fcee92.jpeg',title:"Trek Name C", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178455920-5a590e6f-2e17-47a2-9a00-cde9383c9ddf.jpeg',title:"Trek Name D", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178455943-348a5a5b-d3e6-4cf2-aa11-b2fe6bdd8eae.jpeg',title:"Trek Name A", viewGallery:" view gallery"},
 ];
  const slideLeft =()=>{
  var slider = document.getElementById("slider");
  slider.scrollLeft= slider.scrollLeft - 300;
  }
  const slideRight =()=>{
    var slider = document.getElementById("slider");
    slider.scrollLeft= slider.scrollLeft + 300;
    }

  return (
    <>
    <div className='featured'>
            <h1 className='featured-text'>Beauty of <span className='red'>Harkidoon Trek</span></h1>
            <p className='featured-text'>Har ki doon trek situated at an altitude of 3600 metres is a scenic beauty to behold.Passing through Dharmir,Gangwad,Panwani and Osla it gives an insight into the Himalayan village culture.The trek passes through thick forests giving views of the most beautiful nature.</p>
            <div className='container'>
                <img className='span-3 image-grid-row-2' src= 'https://user-images.githubusercontent.com/103182581/178455793-2bad0cb8-e9c7-433e-be3c-b65799e263b6.jpeg' alt='' />
                <img src= 'https://user-images.githubusercontent.com/103182581/178455793-2bad0cb8-e9c7-433e-be3c-b65799e263b6.jpeg' alt='' />
                <img src= 'https://user-images.githubusercontent.com/103182581/178455795-4f6934b1-72b3-420c-8797-369326f69ded.jpeg' alt='' />
                <img src='https://user-images.githubusercontent.com/103182581/178455860-80f0a1ae-4958-41b8-b5fc-35cae4aa9287.jpeg' alt='' />
                <img src='https://user-images.githubusercontent.com/103182581/178455876-887916f5-5fd5-472d-9173-4da70aef3823.jpeg' alt='' />
            </div>
    </div>

    <div id="body">

<div id="Slider-Container">

       <MdChevronLeft size={40} className="slider-icon left" onClick={slideLeft}/>
       <div id="slider">
          { slides.map((slide,index)=>{
            return(
                <div className="slider-card" key={index}>
                    <div className="slider-card-image" style={{backgroundImage : `url(${slide.image})`}}></div>

                </div>
            )
          })

          }
       </div>
       <MdChevronRight size={40} className="slider-icon right" onClick={slideRight}/>

      </div>

    </div>

  </>

  )
}

export default HarKiDun;