import React from "react";
import "./ESTournaments.css";
import FirstImg from "./img/1.webp";
import SecondImg from "./img/2.webp";
import ThirdImg from "./img/3.webp";
import FourthImg from "./img/4.webp";
import FifthImg from "./img/5.webp";
import SixthImg from "./img/6.webp";
import Carousel from 'react-bootstrap/Carousel';
function ESTournaments() {
  return (
    <main id="esports-tournament">
      <div className="es-content">
        <div class="con1">
          <h1 class="main-heading">E-sports Tournament</h1>
        </div>
        <div class="con3 flexcol">
          <div class="bgmi-content">
            <h1 class="bgmi-heading">Call of Duty Mobile</h1>
            <p class="sub-bgmi-content bold">
              <b>The Thrill of Call of Duty on the go.</b>
            </p>
            <p class="sub-bgmi-content">
              Bring in your teammates to the Battle Royale Arena and fight for
              glory. The tournament's format is a multistage knockout.
            </p>
            <p class="sub-bgmi-content">
              Also to add some more fun we've got exciting prizes at each
              stage. So even if you don't make it to the Finals you'll still
              be rewarded. Explore the hidden gamer in you and show your
              combat skills in front of everyone.
            </p>
          </div>
          <div class="bgmi-photo m1">
            <Carousel nextLabel={null} prevLabel={null} fade={true} interval={2500}>
              <Carousel.Item>
                <iframe
                  class="sub-bgmi-photo"
                  src="https://www.youtube.com/embed/uiUT_QtoH9s"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="sub-bgmi-photo"
                  src={SecondImg}
                  alt="Second slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="sub-bgmi-photo"
                  src={SixthImg}
                  alt="Third Slide slide"
                />
              </Carousel.Item>
            </Carousel>

          </div>
        </div>
        <div class="con3 flexrev">
          <div class="bgmi-photo">
            <Carousel nextLabel={null} prevLabel={null} fade={true} interval={2500}>
              <Carousel.Item>
                <img
                  alt="BGMI"
                  class="sub-bgmi-photo"
                  src="https://feeds.abplive.com/onecms/images/uploaded-images/2021/11/26/603300006bedda51c709f4d3e4c88e44_original.png?impolicy=abp_cdn&imwidth=720"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="sub-bgmi-photo"
                  src={FirstImg}
                  alt="Second slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="sub-bgmi-photo"
                  src={FifthImg}
                  alt="Third Slide slide"
                />
              </Carousel.Item>
            </Carousel>

          </div>

          <div class="bgmi-content">
            <h1 class="bgmi-heading">Battle Grounds Mobile India</h1>

            <p class="sub-bgmi-content bold">
              An Inter-college Tournament based on PUBG Mobile.
            </p>
            <p></p>
            <p class="sub-bgmi-content">
              Proud of your gaming strategy and tactics? Then, this is a perfect
              place for you to show your skills where you'll be competing with
              the best players of several other colleges and IITs.
            </p>
            <p class="sub-bgmi-content">
              Prove your metal and take the prizes and trophy home. Remember
              only the best of the best can survive here!
            </p>
          </div>
        </div>

        <div class="con3 flexcol">
          <div class="bgmi-content">
            <h1 class="bgmi-heading">Valorant</h1>
            <p class="sub-bgmi-content bold">
              <b>The Thrill of Valorant on the go.</b>
            </p>
            <p class="sub-bgmi-content">
              Build your team of Five and fight for glory. The tournament's
              format is a multistage knockout.
            </p>
            <p class="sub-bgmi-content">
              Also to add some more fun we've got exciting prizes at each stage.
              So even if you don't make it to the Finals you'll still be
              rewarded. Explore the hidden gamer in you and show your combat
              skills in front of everyone.
            </p>
          </div>

          <div class="bgmi-photo m1">
            <Carousel nextLabel={null} prevLabel={null} fade={true} interval={2500}>
              <Carousel.Item>
                <img
                  alt="BGMI"
                  class="sub-bgmi-photo"
                  src="https://cdn.pocket-lint.com/r/s/970x/assets/images/152432-games-feature-what-is-valorant-a-guide-to-the-free-to-play-fps-with-tips-on-how-to-win-image3-muha6tfgev.jpg"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="sub-bgmi-photo"
                  src={ThirdImg}
                  alt="Second slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="sub-bgmi-photo"
                  src={FourthImg}
                  alt="Third Slide slide"
                />
              </Carousel.Item>
            </Carousel>

          </div>
        </div>
      </div>
    </main>
  );
}

export default ESTournaments;
