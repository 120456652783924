import React, { useState, useRef } from "react";
import "./CardSlider.css";
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import Kedarkantha from "./Treks/Kedarkantha";
import Brahmatal from "./Treks/Brahmatal";
import PangarchulaPeak from "./Treks/PangarchulaPeak";
import KareriLake from "./Treks/KareriLake";
import Roopkund from "./Treks/Roopkund";
import HarKiDun from "./Treks/HarKiDun";
import Baradasar from "./Treks/Baradasar";
import GangtokTrip from "./Treks/GangtokTrip";
import HamptaPass from "./Treks/HamptaPass";
import Pindari from "./Treks/Pindari";
import Dodital from "./Treks/Dodital";
import ManaliTrek from "./Treks/ManaliTrek";
import Sikkim from "./Treks/Sikkim";
import KauriPass from "./Treks/KuariPass";
import Digah from "./Treks/Digah"
import { useEffect } from "react";

const CardSlider = (props) => {
  const featuredTrekRef = useRef();

  const slides = [
    { image: 'https://user-images.githubusercontent.com/103182581/178450860-bfcecbf2-91d4-4a67-9678-1521ae7e587f.jpeg', title: "Kedarkantha Trek", viewGallery: " view gallery" },
    { image: 'https://user-images.githubusercontent.com/103182581/178453627-93e84c15-71fc-45b4-922a-5c1d15753639.jpeg', title: "Pangarchula Peak Trek", viewGallery: " view gallery" },
    { image: 'https://user-images.githubusercontent.com/103182581/178454265-a9509eb3-6d86-4993-9418-61de0bf9ebad.jpeg', title: "Kareri Lake Summer Trek", viewGallery: " view gallery" },
    { image: 'https://user-images.githubusercontent.com/103182581/178455157-3a2d4a5a-6696-47da-ba89-f6677f2230f8.jpeg', title: "Roopkund Trek", viewGallery: " view gallery" },
    { image: 'https://user-images.githubusercontent.com/103182581/178455951-ff0eafc8-b9b6-4b16-8d20-211874cb1f96.jpeg', title: "Harkidoon Trek", viewGallery: " view gallery" },
    { image: 'https://user-images.githubusercontent.com/103182581/178456584-6c190139-815a-405b-a690-d1b59643c9d3.jpeg', title: "Bardarsar Trek", viewGallery: " view gallery" },
    { image: 'https://user-images.githubusercontent.com/103182581/178497762-eba4aa36-78f0-456f-93f4-e7177c5943c0.jpeg', title: "Gangtok Trip", viewGallery: " view gallery" },
    { image: 'https://user-images.githubusercontent.com/103182581/178492653-109feeb5-560a-4f3d-90c5-cb33a9e56058.jpeg', title: "Hampta Pass Trek", viewGallery: " view gallery" },
    { image: 'https://user-images.githubusercontent.com/103182581/178490785-08a227d1-b46a-4ad4-8649-3aa920c4ea0f.jpeg', title: "Pindari Glacier Trek", viewGallery: "" },
    { image: 'https://user-images.githubusercontent.com/103182581/178494893-5b4707ad-2b26-475e-98ad-f5d00930fd1f.jpeg', title: "Dodital Darwa Trek", viewGallery: "" },
    { image: 'https://user-images.githubusercontent.com/103182581/178496090-00a64045-e321-440b-abf5-472cdf0a110a.jpeg', title: "Manali Trek", viewGallery: "" },
    { image: 'https://user-images.githubusercontent.com/103182581/178491456-4840df16-6f53-4698-a71c-3f756b563531.jpeg', title: "Sikkim Trek", viewGallery: "" },
    { image: 'https://user-images.githubusercontent.com/103182581/178497177-c673d6ec-d6af-47c2-bcd0-ce259bc830ef.jpeg', title: "Kuari Pass Trek", viewGallery: "" },
    { image: 'https://user-images.githubusercontent.com/103182581/178499206-f242cbfa-8156-4244-a79a-79da3ebc2899.jpeg', title: "Brahmatal Trek", viewGallery: "" },
    { image: 'https://user-images.githubusercontent.com/103182581/178493695-27a07a8d-9fcb-4b33-b983-f3b4d07d901c.jpeg', title: "Digah Trip", viewGallery: "" },
  ];
  const slideeLeft = () => {
    var sliderr = document.getElementById("sliderr");
    sliderr.scrollLeft = sliderr.scrollLeft - 500;
  }
  const slideeRight = () => {
    var sliderr = document.getElementById("sliderr");
    sliderr.scrollLeft = sliderr.scrollLeft + 500;
  }
  const [trek, setTrek] = useState("");

  useEffect(
    () => {
      if (featuredTrekRef.current !== null && trek !== '') {
        window.scrollTo(0, featuredTrekRef.current.getBoundingClientRect().top - document.body.getBoundingClientRect().top - 100);
      }
    },
    [trek]
  )

  return (
    <>
      <div id="CardSlider-body">
        <div id="Slider--Container">
          <MdChevronLeft size={40} className="slider--icon left" onClick={slideeLeft} />
          <div id="sliderr">
            {slides.map((slide, index) => {
              return (
                <div className="slider--card" key={index}>
                  <div className="slider--card--image" style={{ backgroundImage: `url(${slide.image})` }}></div>
                  <div className="slider--card--title">{slide.title}</div>

                  <a href="#--" style={{ textDecoration: "none" }} >
                    <button
                      className="CardSliderButton"
                      style={{ verticalAlign: "middle" }}
                      onClick={(e) => {
                        e.preventDefault();

                        if (slide.title === "Kedarkantha Trek") {
                          setTrek(<Kedarkantha />)
                        }
                        else if (slide.title === "Pangarchula Peak Trek") {
                          setTrek(<PangarchulaPeak />)
                        }
                        else if (slide.title === "Kareri Lake Summer Trek") {
                          setTrek(<KareriLake />)
                        }
                        else if (slide.title === "Roopkund Trek") {
                          setTrek(<Roopkund />)
                        }
                        else if (slide.title === "Harkidoon Trek") {
                          setTrek(<HarKiDun />)
                        }
                        else if (slide.title === "Bardarsar Trek") {
                          setTrek(<Baradasar />)
                        }
                        else if (slide.title === "Gangtok Trip") {
                          setTrek(<GangtokTrip />)
                        }
                        else if (slide.title === "Hampta Pass Trek") {
                          setTrek(<HamptaPass />)
                        }
                        else if (slide.title === "Pindari Glacier Trek") {
                          setTrek(<Pindari />)
                        }
                        else if (slide.title === "Kuari Pass Trek") {
                          setTrek(<KauriPass />)
                        }
                        else if (slide.title === "Manali Trek") {
                          setTrek(<ManaliTrek />)
                        }
                        else if (slide.title === "Sikkim Trek") {
                          setTrek(<Sikkim />)
                        }
                        else if (slide.title === "Dodital Darwa Trek") {
                          setTrek(<Dodital />)
                        }
                        else if (slide.title === "Digah Trip") {
                          setTrek(<Digah />)
                        }
                        else if (slide.title === "Brahmatal Trek") {
                          setTrek(<Brahmatal />)
                        }
                      }}
                    >
                      <span>Read More</span>
                    </button>
                  </a>

                </div>
              )
            })

            }
          </div>
          <MdChevronRight size={40} className="slider--icon right" onClick={slideeRight} />

        </div>

      </div>

      <div id="featured-trek" ref={featuredTrekRef}>
        {trek}
      </div>
    </>

  )
}

export default CardSlider;
