import React from "react";
import "./Template.css";
import { MdChevronLeft,MdChevronRight} from 'react-icons/md';





const KareriLake = (props) => {
  const slides = [
  {image: 'https://user-images.githubusercontent.com/103182581/178454315-18530dba-f44e-4a62-a213-98a5f2145082.jpeg' ,title:"Trek Name A", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178454643-a9f6e796-8d9d-42e3-b50d-c579fb67f292.jpeg' ,title:"Trek Name A", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178454329-4ff85139-762a-4643-bfc5-530743d3f60a.jpeg',title:"Trek Name B", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178454661-e8acff87-2c3f-413c-b0b9-2f5f30cf34ec.jpeg',title:"Trek Name C", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178454365-bf750258-5517-44f4-b964-2d5548700572.jpeg',title:"Trek Name D", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178454374-53a6d70b-fd4e-4299-b8bf-42da86f18f85.jpeg',title:"Trek Name A", viewGallery:" view gallery"},
  ];
  const slideLeft =()=>{
  var slider = document.getElementById("slider");
  slider.scrollLeft= slider.scrollLeft - 300;
  }
  const slideRight =()=>{
    var slider = document.getElementById("slider");
    slider.scrollLeft= slider.scrollLeft + 300;
    }

  return (
    <>
    <div className='featured'>
            <h1 className='featured-text'>Beauty of <span className='red'>Kareri Lake Summer</span> Trek</h1>
            <p className='featured-text'>The lake situated at a height of 9650 ft is a hiking trail for those who want to face a real challenge.The trail starting from kareri  village is about 10km long and will take you to a remote village where you can experience life in the country.</p>
            <div className='container'>
                <img className='span-3 image-grid-row-2' src= 'https://user-images.githubusercontent.com/103182581/178454271-b2faae60-1bad-493b-b401-0c74a2ec7027.jpeg' alt='' />
                <img src= 'https://user-images.githubusercontent.com/103182581/178454276-29fc0ae6-e451-4d22-908b-ef9ff3e3249f.jpeg' alt='' />
                <img src='https://user-images.githubusercontent.com/103182581/178454280-d377fac7-945f-4552-9231-5df9c5f5dd80.jpeg' alt='' />
                <img src='https://user-images.githubusercontent.com/103182581/178454280-d377fac7-945f-4552-9231-5df9c5f5dd80.jpeg' alt='' />
                <img src='https://user-images.githubusercontent.com/103182581/178454283-f4b757d8-ab85-4d2e-b18f-df6ad9b0dac2.jpeg' alt='' />
            </div>
    </div>

    <div id="body">

<div id="Slider-Container">

       <MdChevronLeft size={40} className="slider-icon left" onClick={slideLeft}/>
       <div id="slider">
          { slides.map((slide,index)=>{
            return(
                <div className="slider-card" key={index}>
                    <div className="slider-card-image" style={{backgroundImage : `url(${slide.image})`}}></div>

                </div>
            )
          })

          }
       </div>
       <MdChevronRight size={40} className="slider-icon right" onClick={slideRight}/>

      </div>

    </div>

  </>

  )
}

export default KareriLake;