import React from "react";
import "./TreksTrips.css";
import Best from "./Best";
import Slider from './slider/Slider';
import CardSlider from "./CardSlider";

function TreksTrips() {
  return (
    <main id="treks-and-trips">
      <div>
        <Slider />
      </div>

      <Best />
      <div id="card">
        <CardSlider />
      </div>
    </main>
  );
}

export default TreksTrips;