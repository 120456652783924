import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faFacebook,
  faYoutube,
} from "@fortawesome/fontawesome-free-brands";
import { faLocationArrow } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import "./LandingPage.css";
// import LandingModal from "../Modal/Modal";

const LandingPage = () => {
  return (
    <main id="landing-page">
      {/* <LandingModal /> */}

      <section>
        <div>
          <h1>
            <span className="accent-red">T</span>echnology{" "}
            <span className="accent-red">Ad</span>venture{" "}
            <span className="accent-red">S</span>ociety
          </h1>
        </div>

        <Link className="C2A-Btn" to="/about-us">
          <span>
            Begin the
            <br /> Adventurous
            <br /> Journey
          </span>
          <FontAwesomeIcon
            icon={faLocationArrow}
            size="2x"
            className="icon-arrow"
          />
        </Link>
      </section>
      <aside>
        <div className="aside-text">
          <div>
            <b>Adventure Weekend</b>
            <p>
              Adventure is always for doers not for viewers. Our most awaited
              Adventure Weekend with awesome events like...
            </p>
            <a
              href="https://www.youtube.com/watch?v=GNOwQ0_lO30"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faYoutube} size="lg" /> Watch
            </a>
          </div>

          <div className="aside-text">
            <b>Brahmatal Trek</b>
            <p>
              Get a sneak peek of the stunning landscapes and vibrant culture we
              experienced on our recent Brahmatal{"\u00A0"}
              Trek.
            </p>
            <a
              href="https://fb.watch/qzD3ej9BF2/"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faFacebook} size="lg" /> Watch
            </a>
          </div>
        </div>

        <hr />

        <div className="social-container">
          <div className="social-media-btns">
            <a
              href="https://www.facebook.com/TAdS.IIT"
              target="_blank"
              rel="noreferrer"
              className="social-media-btn"
            >
              <FontAwesomeIcon icon={faFacebook} size="2x" />
            </a>
            <a
              href="https://www.instagram.com/tads_iitkgp/"
              target="_blank"
              rel="noreferrer"
              className="social-media-btn"
            >
              <FontAwesomeIcon icon={faInstagram} size="2x" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCO-uXgNOr960Lfm6haTigxw"
              target="_blank"
              rel="noreferrer"
              className="social-media-btn"
            >
              <FontAwesomeIcon icon={faYoutube} size="2x" />
            </a>
          </div>
        </div>
      </aside>
    </main>
  );
};

export default LandingPage;
