import { v4 as uuidv4 } from "uuid";

const dataSlider = [
  {
    id: uuidv4(),
    title: " ",
    subTitle: "  "
  },
  {
    id: uuidv4(),
    title: " ",
    subTitle: "  "
  },
  {
    id: uuidv4(),
    title: " ",
    subTitle: "  "
  },
  {
    id: uuidv4(),
    title: " ",
    subTitle: "  "
  },
  {
    id: uuidv4(),
    title: " ",
    subTitle: "  "
  },
  
];

export default dataSlider;