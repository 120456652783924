import { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import './NavbarSubmenu.css';

/**
 * @param {{
 * title: string,
	* sublinks: {
		* title: string,
		* to: string
	* }[]
 * }} props
 * @returns
 */
const NavbarSubmenu = (props) => {
	// thanks to https://letsbuildui.dev/articles/building-a-dropdown-menu-component-with-react-hooks
	const [isOpen, setIsOpen] = useState(false);
	const dropdownBtnRef = useRef();

	const onTriggerClick = () => {
		setIsOpen(!isOpen);
	}

	useEffect(
		() => {
			const onWindowClick = (e) => {
				if (dropdownBtnRef.current !== null && !dropdownBtnRef.current.contains(e.target)) {
					setIsOpen(false);
				}
			}

			// If the item is active (ie open) then listen for clicks
			if (isOpen) {
				window.addEventListener('click', onWindowClick);
			}

			return () => {
				window.removeEventListener('click', onWindowClick);
			}

		}, [isOpen]
	)

	const {
		title,
		sublinks
	} = props;

	return (
		<div className="navbar-submenu">
			<button
				className={['navbar-link', 'navbar-submenu-trigger', isOpen ? 'trigger-open' : ''].join(' ')}
				onClick={onTriggerClick}
				ref={dropdownBtnRef}
			>
				<span>{title ?? 'Submenu Trigger'}</span>
				<FontAwesomeIcon icon={isOpen ? faCaretUp : faCaretDown} size="sm" className="navbar-submenu-caret" />
			</button>

			<div className="navbar-submenu-container" style={isOpen ? {} : {display: 'none'}}>
				{
					isOpen &&
					sublinks.map((sublink) => {
						return (
							<Link to={sublink.to} className="navbar-link">{sublink.title}</Link>
						)
					})
				}
			</div>
		</div>
	)
};

export default NavbarSubmenu;
