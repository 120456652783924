import { MEMBERS } from '../team';

import './Members.css';

function Members() {
  return (
    <div id="members-list">
      {
        MEMBERS.map((member, i) => (
          <p className="member" key={i}>{member.name}</p>
        ))
      }
    </div>
  )
}

export default Members;
