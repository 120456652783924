
import React from 'react'




import './Best.css'

const Best = () => {
    return (
        <div className='best'>
            <h1>Adore <span className='red'>the Beauty</span> of World</h1>
             <div>
                <p><span className='bold'>"Let's wander where the WiFi is weak."</span></p>

            </div>
            
        </div>


    )
}

export default Best