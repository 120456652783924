import ASS from './img/ASS.jpg';
import AA from './img/AA.jpeg';
import AAT2 from './img/AAT2.jpg';


export const CURRENT_GOVERNORS = [
	{
		name: 'Praveen Kumar Yadav',
		img: ASS,
		links: {
			fb: 'https://www.facebook.com/profile.php?id=100004452134754&mibextid=ZbWKwL',
			insta: 'https://www.instagram.com/praveen.x18?igsh=ZWdhMGhyazF0M2Nx',
			// linkedin: 'https://www.linkedin.com/in/aditya-singh-shekhawat-69519a226/'
		}
	},
	{
		name: 'Simran Srivastava',
		img: AA,
		links: {
			fb: 'https://www.facebook.com/profile.php?id=100087767851393&mibextid=ZbWKwL',
			insta: 'https://www.instagram.com/simran_srivastava17?igsh=MWVzeHJjaHhhcjUwdA==',
			// linkedin: 'https://www.linkedin.com/in/archieavirati/'
		}
	},
	{
		name: 'Abhishek Chaudhary',
		img: AAT2,
		links: {
			fb: 'https://www.facebook.com/profile.php?id=100039444165682&mibextid=ZbWKwL',
			insta: 'https://www.instagram.com/the_abhi_jatt?igsh=MWJqeWg4OWV0Y3psaQ==',
			// linkedin: 'https://www.linkedin.com/in/ashwini-tayade-233b5422a/'
		}
	},
	// {
	// 	name: 'Harsh Khasbage',
	// 	img: HK,
	// 	links: {
	// 		fb: 'https://www.facebook.com/harshkhasbage77/',
	// 		insta: 'https://www.instagram.com/harshkhasbage77/',
	// 		linkedin: 'https://www.linkedin.com/in/harshkhasbage77'
	// 	}
	// }
]

export const MEMBERS = [
	// {name: 'Aaditi Ujgare'},
	// {name: 'Akash Katravath'},
	// {name: 'Ananya A'},
	// {name: 'Abhishek Chaudhary'},
	// {name: 'Archana S'},
	// {name: 'Abhishek Sajwan'},
	// {name: 'Bhawani Shankar ola'},
	// {name: 'Harsh Raj Gupta'},
	// {name: 'Kartikey Verma'},
	// {name: 'Mahim Dungarwal'},
	// {name: 'M.Kartheek Naik'},
	// {name: 'Swara Mohite'},
	// {name: 'Nishant Mishra'},
	// {name: 'Palak Kothari'},
	// {name: 'Parth Prakash Mall'},
	// {name: 'Praveen Kumar Yadav'},
	// {name: 'Roshmita Rajesh'},
	// {name: 'Sandeep Patel'},
	// {name: 'Sanjana Haldar'},
	// {name: 'Shivam Kumar'},
	// {name: 'Shreyans Sharma'},
	// {name: 'Shreyansh Mishra'},
	// {name: 'Shreyas Bahadure'},
	// {name: 'Shrirang Elkunchwar'},
	// {name: 'Simran Kumari'},
	// {name: 'Sushant Pandey'},
	// {name: 'Yashika'},
	// {name: 'Dhanavath Pranay Naik'},
	// {name: 'Indra Kumar mv'},
	// {name: 'Nehal Gangadhar Chaudhari'},
	// {name: 'Rahul Verma'},
	// {name: 'Shubham Vishwakarma'}
	{name: 'Akash Maurya'},
{name: 'Aman Singh Chauhan'},
{name: 'Annu Chaurasiya'},
{name: 'Arnab Chakraborty'},
{name: 'Atreya Bordoloi'},
{name: 'Avi Kumar'},
{name: 'Ayush Singh'},
{name: 'Brajesh Kumar Patel'},
{name: 'Chelsi Pal'},
{name: 'Gokul R'},
{name: 'Krishna Vamshi'},
{name: 'Monika Kumari'},
{name: 'Namah Kushwaha'},
{name: 'Piyush Agrawal'},
{name: 'Pooja Paswan'},
{name: 'Pradeep Vadithya'},
{name: 'Pradhyumna Purohit'},
{name: 'Pranit Chaudhary'},
{name: 'Praveen Jangir'},
{name: 'Ritvik Puvvada'},
{name: 'Sabita Kumari'},
{name: 'Shubhraj Singh Dodiya'},
{name: 'Smriti'},
{name: 'Sounak Sarkar'},
{name: 'Vaibhava Goyal'},
{name: 'Vasavi Nenavath'},
{name: 'Vinay Agrahari'},
{name: 'Nimal Farhan Aripurath'},
{name: 'Amar Kumar Pandey'},
{name: 'Anupal Goswami'},
{name: 'Mandeep Das'}
]

export const FOREVER_GOVERNORS = {
	names: [
		'Mr. Ankit Joshi',
		'Mr. Kushal Suhasaria',
		'Mr. Shekhar Suman'
	],
	tenure: '2009-∞'
}

export const PREVIOUS_GOVERNORS = [
	{
		names: [
			"Mr. Aditya Singh Shekhawat",
			"Ms. Archie Avirati",
			"Mr. Harsh Khasbage",
			"Ms. Ashwini Tayade"
		],
		tenure: "2023-2024"
	},
	{
		names: [
			"Mr. Abhinil Kumar",
			"Ms. Mainka Raj Todawat",
			"Mr. Suraj Gupta",
			"Mr. Vansh Agarwal"
		],
		tenure: "2022-2023"
	},
	{
		names: [
			"Mr. Vivek Beniwal",
			"Ms. Aakriti Gupta",
			"Mr. Vishal Sharma"
		],
		tenure: "2021-2022"
	},
	{
		names: [
			"Mr. Alok Munshi",
			"Mr. Rudraksh Gupta",
			"Ms. Ardra Raj",
			"Mr. Shubham Sahu"
			],
		tenure: "2020-2021"
	},
	{
		names: [
			"Mr. Dinesh Chandra",
			"Mr. Mohit Daroch",
			"Ms. Nidhi Swami",
			"Ms. Simran Vaidya",
			"Mr. Yashwant Singh"
		],
		tenure: "2019-2020"
	},
	{
		names: [
			"Mr. Agam Gupta",
			"Mr. Ankit Meena",
			"Mr. Anupam Choudhary",
			"Mr. Mohith Majety",
			"Mr. Nikhil Raj"
		],
		tenure: "2018-2019"
	},
	{
		names: [
			"Mr. Aakash Yadav",
			"Mrs. Mounika Rampalli",
			"Mr. Neerav Jain",
			"Mr. Nitin Kush",
			"Mr. Shubham Khandelwal",
			"Mr. Shantanu Mandhane"
		],
		tenure: "2017-2018"
	},
	{
		names: [
			"Mr. Abhishek Ranjan Jaiswal",
			"Ms. Arpita Mangal",
			"Mr. Kedar Prasad",
			"Mr. Mayank Srivastava",
			"Mr. Mohit Kalal",
			"Ms. Jayanti Madhei"
		],
		tenure: "2016-2017"
	},
	{
		names: [
			"Mr. Chandra Mohan Meena",
			"Mr. Jinu JS",
			"Mr. Jitesh Kumar Shivgam",
			"Mr. Paras Prateek",
			"Mr. Supriya Sinha",
			"Mr. Yogesh Charaipotra"
		],
		tenure: "2015-2016"
	},
	{
		names: [
			"Mr. Gagan Goel",
			"Mr. Hitendra Jharwal",
			"Mr. Souvik Maity",
			"Mr. Paras Prateek",
			"Ms. Manisa Mondal"
		],
		tenure: "2014-2015"
	},
	{
		names: [
			"Mrs. Akanksha Chauhan",
			"Mr. Biren Nayak",
			"Ms. Namita Mittal",
			"Mr. Praveen Nayak"
		],
		tenure: "2013-2014"
	},
	{
		names: [
			"Mr. Ankit Saxena",
			"Mr. Divyanshu Sharma",
			"Mr. Nitin Garg"
		],
		tenure: "2012-2013"
	},
	{
		names: [
			"Mr. Nawal Agrawal",
			"Mr. Nitin Nagwani",
			"Mr. Sanghpriya Rahul"
		],
		tenure: "2011-2012"
	}
]