import React from "react";
import "./Template.css";
import { MdChevronLeft,MdChevronRight} from 'react-icons/md';





const Kedarkantha = (props) => {
  const slides = [{
    image: 'https://user-images.githubusercontent.com/103182581/178450978-31f4012d-e8f3-4481-b03f-c2552bd80117.jpeg',title:"Trek Name A", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178450991-8a59251c-bf34-4069-b3a3-0daacdf632a7.jpeg' ,title:"Trek Name A", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178451022-8fa28d3e-8a7c-4eec-a012-0ab88d9bb5d3.jpeg' ,title:"Trek Name A", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178451152-acc71284-a163-4d26-8165-b0f2cc07867e.jpeg',title:"Trek Name B", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178451161-46c2884f-860d-4415-8188-781fdb498f45.jpeg',title:"Trek Name C", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178451179-e23b4a94-60c1-4c60-ada9-bf60ca643d44.jpeg',title:"Trek Name D", viewGallery:" view gallery"},
  ];
  const slideLeft =()=>{
  var slider = document.getElementById("slider");
  slider.scrollLeft= slider.scrollLeft - 300;
  }
  const slideRight =()=>{
    var slider = document.getElementById("slider");
    slider.scrollLeft= slider.scrollLeft + 300;
    }

  return (
    <>
    <div className='featured'>
            <h1 className='featured-text'>Beauty of <span className='red'>Kedarkantha Trek</span></h1>
            <p className='featured-text'>Kedarkantha is a mountain peak of the Himalayas in Uttarakhand, India. Its elevation is 12,500 ft. Kedarkantha is located within Govind Wildlife Sanctuary in Uttarkashi district.It is easily one of the most popular treks in India — popular amongst seasoned trekkers and beginners alike.The Trek goes through a lot of beautiful sites smwhivh include Sankri, Juda Ka Talaab and a lot more...</p>
            <div className='container'>
                <img className='span-3 image-grid-row-2' src= 'https://user-images.githubusercontent.com/103182581/178450871-bea9a114-778b-4a63-b974-7aabae757626.jpeg' alt='' />
                <img src= 'https://user-images.githubusercontent.com/103182581/178450877-6130e44e-46db-43df-83d5-288339dcabb8.jpeg'  alt='' />
                <img src= 'https://user-images.githubusercontent.com/103182581/178450886-cdc205e9-1a25-47b6-8fd3-ced5e558e657.jpeg' alt='' />
                <img src= 'https://user-images.githubusercontent.com/103182581/178450927-f0b30062-844b-4a4d-a3c0-ebca7723fe30.jpeg' alt='' />
                <img src= 'https://user-images.githubusercontent.com/103182581/178450970-d9432143-17c4-41a9-8874-46ceaf2ec3d7.jpeg' alt='' />
            </div>
    </div>

    <div id="body">

<div id="Slider-Container">

       <MdChevronLeft size={40} className="slider-icon left" onClick={slideLeft}/>
       <div id="slider">
          { slides.map((slide,index)=>{
            return(
                <div className="slider-card" key={index}>
                    <div className="slider-card-image" style={{backgroundImage : `url(${slide.image})`}}></div>

                </div>
            )
          })

          }
       </div>
       <MdChevronRight size={40} className="slider-icon right" onClick={slideRight}/>

      </div>

    </div>

  </>

  )
}

export default Kedarkantha;