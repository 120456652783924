import CurrentGovernors from './CurrentGovernors/CurrentGovernors';
import PreviousGovernors from './PreviousGovernors/PreviousGovernors';
import Members from './Members/Members';

import './TeamPageComponent.css';

export default function TeamPageComponent() {
	return (
		<main id="our-team">
			<h1 className="heading">Governors 2024-2025</h1>
			<CurrentGovernors />
			<h1 className="heading">Previous Governors</h1>
			<PreviousGovernors />
			<h1 className="heading">Members 2024-2025</h1>
			<Members />
		</main>
	)
}