import React, { useState } from "react";
import s from "./AdventureWeekend.module.css";
import { BsFillPersonFill, BsFillTelephoneFill } from "react-icons/bs";
import { IoMdMailUnread } from "react-icons/io";
import { Link } from "react-router-dom";
import axios from "axios";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

function getAmount(event) {
  if (event == "Laser Tag") {
    return 150;
  } else if (event == "Go Karting") {
    return 250;
  } else if (event == "Human Foosball") {
    return 600;
  } else if (event == "Combo") {
    return 420;
  }
}

function Slot({ setSlot }) {
  return (
    <div className={s.wrap}>
      <h2 style={{ color: "black", textAlign: "center", margin: "3px" }}>
        Select Time Slot
      </h2>
      <div
        className={s.redirect}
        style={{ textAlign: "center", textColor: "red" }}
      >
        <div
          className={s.slot_days}
          style={{ color: "black", textAlign: "center", margin: "3px" }}
        >
          <a
            className={s.period}
            onClick={() => {
              setSlot("Saturday Morning");
            }}
          >
            Saturday Morning (9am - 1pm)
          </a>
          <a
            className={s.period}
            onClick={() => {
              setSlot("Saturday Evening");
            }}
          >
            Saturday Evening (2pm - 5pm)
          </a>
          <a
            className={s.period}
            onClick={() => {
              setSlot("Sunday Morning");
            }}
          >
            Sunday Morning (9am - 1pm)
          </a>
          <a
            className={s.period}
            onClick={() => {
              setSlot("Sunday Evening");
            }}
          >
            Sunday Evening (2pm - 5pm)
          </a>
        </div>
      </div>
    </div>
  );
}

function AdventureWeekend() {
  const [inputVal, setInputVal] = useState({
    name: "",
    email: "",
    phone: "",
    slot: "",
    time: "",
    event: "",
  });

  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onw2lBtgmeuDUfnJVp43UpcaiTerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function openRazorpayScreen() {
    try {
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }

      // creating a new order
      let inputData = { amount: getAmount(inputVal.event) };
      console.log(inputData);
      const result = await axios.post(
        `${BACKEND_URL}/api/razorpay/create-order`,
        inputData
      );

      if (!result) {
        alert("Server error. Are you online?");
        return;
      }

      // // Getting the order details back
      const { amount, id: order_id, currency } = result.data.order;

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        amount: amount.toString(),
        currency: currency,
        name: "Technology Adventure Society",
        description: "Adventure Weekend I - Registration",
        // TODO : Insert TAdS Logo Here
        // image: { logo },
        order_id: order_id,
        handler: async function (response) {
          const data = {
            orderCreationId: order_id,
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
            ...inputVal,
          };

          const result = await axios.post(
            `${BACKEND_URL}/api/razorpay/payment-success`,
            data
          );

          alert(result.data.message);

          setLoading(false);
          setInputVal({
            name: "",
            email: "",
            phone: "",
            slot: "",
            time: "",
            event: "",
          });
          setConfirm(false);
        },
        prefill: {
          name: inputVal.name,
          email: inputVal.email,
          contact: inputVal.phone,
        },
        theme: {
          color: "#61dafb",
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (err) {
      console.log(err.message);
      setLoading(false);
      setInputVal({
        name: "",
        email: "",
        phone: "",
        slot: "",
        time: "",
        event: "",
      });
      setConfirm(false);
    }
  }

  async function onSubmit(e) {
    e.preventDefault();
    if (confirm) {
      try {
        setLoading(true);
        openRazorpayScreen();
      } catch (err) {
        console.log(err);
      }
    }
  }

  return (
    <>
      {loading && (
        <div className={s.modal}>
          <div className={s.modal_content}>
            <h3>Instructions</h3>
            <>
              <p>1. Wear shoes while participating in the games.</p>
              <p>2. Wear full sleeves to avoid any cut or injury.</p>
            </>
            <p>
              In some time the pdf ticket will be downloaded including all the
              details. Bring the same at the venue (Gymkhana) for verification
              purpose.
            </p>
          </div>
        </div>
      )}

      <main id="adventure-weekend2" className={s.adventureWeekend}>
        {inputVal.slot ? (
          <>
            <div className={s.wrapper}>
              <div className={s.title}>
                <h1>
                  Adventure Weekend 1.O <br />
                  Registration Form
                </h1>
                <p>
                  Do Checkout the{" "}
                  <Link
                    to="/events/adventure-weekend"
                    style={{ color: "crimson" }}
                  >
                    Previous Year Gallery
                  </Link>
                </p>
              </div>
              <div className={s.form_wrap}>
                <div className={s.input_wrap}>
                  <label htmlFor="name">
                    <span className={s.icon}>
                      <BsFillPersonFill />
                    </span>
                    Name
                  </label>
                  <input
                    id="name"
                    value={inputVal.name}
                    onChange={(e) =>
                      setInputVal((prev) => ({ ...prev, name: e.target.value }))
                    }
                    className={s.input}
                  />
                </div>

                <div className={s.input_wrap}>
                  <label htmlFor="email">
                    <span className={s.icon}>
                      <IoMdMailUnread />
                    </span>
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    value={inputVal.email}
                    onChange={(e) =>
                      setInputVal((prev) => ({
                        ...prev,
                        email: e.target.value,
                      }))
                    }
                    className={s.input}
                  />
                </div>

                <div className={s.input_wrap}>
                  <label htmlFor="phone">
                    <span className={s.icon}>
                      <BsFillTelephoneFill />
                    </span>
                    Contact Number
                  </label>
                  <input
                    type="text"
                    id="phone"
                    value={inputVal.phone}
                    onChange={(e) =>
                      setInputVal((prev) => ({
                        ...prev,
                        phone: e.target.value,
                      }))
                    }
                    className={s.input}
                  />
                </div>

                <div className={s.input_wrap}>
                  <label htmlFor="event">Events you want to register</label>
                  <div
                    className={s.radio_option}
                    onChange={(e) => {
                      setInputVal((prev) => ({
                        ...prev,
                        event: e.target.value,
                      }));
                    }}
                  >
                    <div className={s.radio}>
                      <input
                        type="radio"
                        value="Laser Tag"
                        name="event"
                        id="p"
                      />
                      <label htmlFor="p">Only Zorbing (Rs. 150)</label>
                    </div>
                    <div className={s.radio}>
                      <input
                        type="radio"
                        value="Go Karting"
                        name="event"
                        id="q"
                      />
                      <label htmlFor="q">Only Paintball (Rs. 170)</label>
                    </div>
                    <div className={s.radio}>
                      <input
                        type="radio"
                        value="Human Foosball"
                        name="event"
                        id="r"
                      />
                      <label htmlFor="r">Only Human Foosball (Rs. 90)</label>
                    </div>
                    <div className={s.radio}>
                      <input type="radio" value="Combo" name="event" id="s" />
                      <label htmlFor="s">
                        Combo: All above events + Archery (Rs. 390)
                      </label>
                    </div>
                  </div>
                </div>

                <div className={s.input_wrap}>
                  <label>Select Slot</label>

                  {inputVal.slot.split(" ")[1].toLowerCase() == "morning" ? (
                    <>
                      <div
                        className={s.radio_option}
                        onChange={(e) => {
                          setInputVal((prev) => ({
                            ...prev,
                            time: e.target.value,
                          }));
                        }}
                      >
                        <div className={s.radio}>
                          <input
                            type="radio"
                            value="9am-10am"
                            name="slot"
                            id="a"
                          />
                          <label htmlFor="a">9am-10am</label>
                        </div>
                        <div className={s.radio}>
                          <input
                            type="radio"
                            value="10am-11am"
                            name="slot"
                            id="b"
                          />
                          <label htmlFor="b">10am-11am</label>
                        </div>
                        <div className={s.radio}>
                          <input
                            type="radio"
                            value="11am-12pm"
                            name="slot"
                            id="c"
                          />
                          <label htmlFor="c">11am-12pm</label>
                        </div>
                        <div className={s.radio}>
                          <input
                            type="radio"
                            value="12pm-1pm"
                            name="slot"
                            id="d"
                          />
                          <label htmlFor="d">12pm-1pm</label>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div
                      className={s.radio_option}
                      onChange={(e) => {
                        setInputVal((prev) => ({
                          ...prev,
                          time: e.target.value,
                        }));
                      }}
                    >
                      <div className={s.radio}>
                        <input
                          type="radio"
                          value="2pm-3pm"
                          name="slot"
                          id="a"
                        />
                        <label htmlFor="a">2pm-3pm</label>
                      </div>
                      <div className={s.radio}>
                        <input
                          type="radio"
                          value="3pm-4pm"
                          name="slot"
                          id="b"
                        />
                        <label htmlFor="b">3pm-4pm</label>
                      </div>
                      <div className={s.radio}>
                        <input
                          type="radio"
                          value="4pm-5pm"
                          name="slot"
                          id="c"
                        />
                        <label htmlFor="c">4pm-5pm</label>
                      </div>
                      <div className={s.radio}>
                        <input
                          type="radio"
                          value="5pm-6pm"
                          name="slot"
                          id="d"
                        />
                        <label htmlFor="d">5pm-6pm</label>
                      </div>
                    </div>
                  )}
                </div>

                {/* <div className={s.input_wrap}>
                  <label>
                    Upload Screenshot of your Payment Receipt
                    (Gpay/PayTM/PhonePay on 6281714795 or vanshagarwal9777@sbi)
                  </label>
                  <input
                    type="file"
                    onChange={(e) => setScreenshot(e.target.files[0])}
                  />
                </div> */}

                <div
                  className={s.input_wrap}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    value={confirm}
                    onChange={(e) => {
                      setConfirm((x) => !x);
                    }}
                    id="check"
                    name="check"
                  />
                  <label style={{ margin: 0 }} htmlFor="check">
                    I agree to follow all the safety rules while participating
                    in all the games. I will wear shoes and full wears while
                    participating.
                  </label>
                </div>
                {/* <div>
                  <p style={{ color: "black", marginBottom: "1rem" }}>
                    {" "}
                    **Important Note : After clicking once on "Pay Now"
                    (wait for sometime), a pdf will be generated and downloaded
                    on your device. In order to participate in any of the games,
                    it is a must to carry that pdf (online mode)
                  </p>
                </div> */}

                <button
                  disabled={loading}
                  onClick={onSubmit}
                  className={s.submit_btn}
                >
                  Pay Now
                </button>
              </div>
            </div>
          </>
        ) : (
          <Slot
            setSlot={(value) => {
              setInputVal((prev) => ({ ...prev, slot: value }));
            }}
          />
        )}
      </main>
    </>
  );
}

export default AdventureWeekend;
