import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faFacebook,
  faLinkedin,
} from "@fortawesome/fontawesome-free-brands";

import "./CurrentGovernors.css";
import { CURRENT_GOVERNORS } from '../team';

export default function CurrentGovernors() {
  return (
    <div id="govs-list">
      {
        CURRENT_GOVERNORS.map((governor, i) => (
          <div className="card-gov" key={i}>
            <img className="card-img" src={governor.img} alt={governor.name} />

            <h4 className="card-title">{governor.name}</h4>
            <hr className="gov-card-divide" />

            <div className="card-footer">
              {
                governor.links.fb && (
                  <a className="footer-link" href={governor.links.fb} target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faFacebook} size="2x" />
                  </a>
                )
              }
              {
                governor.links.insta && (
                  <a className="footer-link" href={governor.links.insta} target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faInstagram} size="2x" />
                  </a>
                )
              }
              {
                governor.links.linkedin && (
                  <a className="footer-link" href={governor.links.linkedin} target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faLinkedin} size="2x" />
                  </a>
                )
              }
            </div>
          </div>
        ))
      }
    </div>
  );
}
