import React from "react";
import "./GuestLecture.css";
import FirstImg from "./img/1.webp";
import SecondImg from "./img/2.webp";
import ThirdImg from "./img/3.webp";
import Carousel from 'react-bootstrap/Carousel';
function GuestLecture() {
  return (
    <main id="guest-lecture">
      <div class="contnt">
        <h3 className="accent-red">
          <span>
            Guest Lecture <br /> By Lt. Col. Romil Barthwal
          </span>
        </h3>

        <p>
          Technology Adventure Society recently conducted our very first Guest
          Lecture. We had a great pleasure to host Lt. Col. Romil Barthwal for
          Motivation Talk. He is an alumnus of IIT Kharagpur and has
          successfully led a 20-member expedition to scale Mt. Everest. Amongst
          other adventure activities, he has done 200, 300, 400, 600km cycling
          brevets in one year to achieve the title of a super Randonneur. Not
          only was he a podium finisher in an Ironman event but also a certified
          white water rafting course expert. Always stepping forward to face
          adventure, he has done bungee jumping, duathlons, paramotor,
          parasailing, paragliding and para trooping too. Sharing his experience
          he motivated students to keep actively participating in such adventure
          sports to get out of such a monotonous life.
        </p>
        <div class="Carousel">
          <Carousel nextLabel={null} prevLabel={null} fade={true} interval={3000} pause={false}>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={FirstImg}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={SecondImg}
                alt="Second slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={ThirdImg}
                alt="Third slide"
              />
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    </main>
  );
}

export default GuestLecture;
