import { Component, createRef } from 'react'
import loader from './Loader.mp4'
import './Loader.css';

export class Loader extends Component {
   loaderWrapperRef = createRef();

   componentDidMount(){
      setTimeout(
         () => {
            this.props.setIsLoaded(true);
            this.loaderWrapperRef.current.style.setProperty('opacity', 0);

            setTimeout(() => this.loaderWrapperRef.current.style.setProperty('display', 'none'), 400);
         },
         5000
      )
   }
   render() {
      return (
         <div className="Loader-wrapper" ref={this.loaderWrapperRef}>
            {!this.props.isLoaded &&
            <video autoPlay loop muted className='Loader-video' width="100%" >
               <source src={loader} alt='loading...' />
            </video>}
         </div>
      )
   }
}

export default Loader