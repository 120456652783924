import React from "react";
import "./Template.css";
import { MdChevronLeft,MdChevronRight} from 'react-icons/md';




const GangtokTrip = (props) => {
  const slides = [{image: 'https://user-images.githubusercontent.com/103182581/178497830-ca9c17f6-ae0a-4964-acf7-8abd8f02b57b.jpeg',title:"Trek Name A", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178497826-62b56616-2b94-4e70-a962-7883a49c36d8.jpeg' ,title:"Trek Name A", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178497832-fe9c6a5e-707e-4b0f-a7ee-33e07d647755.jpeg' ,title:"Trek Name A", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178498010-93539063-687f-45d7-9d52-5e82bbb177b4.jpeg',title:"Trek Name B", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178498015-f840101c-63c5-4467-bfa0-5e7c0d8d3f82.jpeg',title:"Trek Name C", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178498266-125ba541-dcf3-41b6-8452-a3bd40791967.jpeg',title:"Trek Name D", viewGallery:" view gallery"},
  ];
  const slideLeft =()=>{
  var slider = document.getElementById("slider");
  slider.scrollLeft= slider.scrollLeft - 300;
  }
  const slideRight =()=>{
    var slider = document.getElementById("slider");
    slider.scrollLeft= slider.scrollLeft + 300;
    }

  return (
    <>
    <div className='featured'>
            <h1 className='featured-text'>Beauty of <span className='red'>Gangtok Trip</span></h1>
            <p className='featured-text'>Incredibly alluring, pleasantly boisterous and wreathed in clouds - Gangtok, the capital of Sikkim, is one of the most popular hill stations in India. Lying at the height of 1650 m above sea level, the town during its bright sunny days offers spectacular views of Mt. Kanchenjunga. Gangtok is a base for adventure enthusiasts comprising of trekkers and campers to the Himalayan mountain ranges.</p>
            <div className='container'>
                <img className='span-3 image-grid-row-2' src= 'https://user-images.githubusercontent.com/103182581/178497767-38dba13f-2e27-450d-a572-f022507c18e8.jpeg' alt='' />
                <img src= 'https://user-images.githubusercontent.com/103182581/178497771-e5d171a8-072b-4fd0-ac6d-2c66283a0c2d.jpeg' alt='' />
                <img src='https://user-images.githubusercontent.com/103182581/178497781-3fe99314-5da2-4ac6-b172-434e79867e94.jpeg' alt='' />
                <img src='https://user-images.githubusercontent.com/103182581/178497804-f6b99ae1-0073-4d31-b212-04cf977ffaf1.jpeg' alt='' />
                <img src='https://user-images.githubusercontent.com/103182581/178497820-ba108c1d-5a60-4522-836b-2fab199ddbf9.jpeg' alt='' />

            </div>
    </div>

    <div id="body">

<div id="Slider-Container">

       <MdChevronLeft size={40} className="slider-icon left" onClick={slideLeft}/>
       <div id="slider">
          { slides.map((slide,index)=>{
            return(
                <div className="slider-card" key={index}>
                    <div className="slider-card-image" style={{backgroundImage : `url(${slide.image})`}}></div>

                </div>
            )
          })

          }
       </div>
       <MdChevronRight size={40} className="slider-icon right" onClick={slideRight}/>

      </div>

    </div>

  </>

  )
}

export default GangtokTrip;