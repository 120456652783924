import React from "react";
import "./Template.css";
import { MdChevronLeft,MdChevronRight} from 'react-icons/md';




const Pindari = (props) => {
  const slides = [{image: 'https://user-images.githubusercontent.com/103182581/178490831-5aa40cfa-b6c4-4cb6-b0ff-0f927e38b36e.jpeg',title:"Trek Name A", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178490856-b709611b-b953-41d5-88db-456879d165ac.jpeg' ,title:"Trek Name A", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178490864-cc12dd2c-7942-4043-8564-ad3b6287847d.jpeg' ,title:"Trek Name A", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178490794-a4f5e6fe-bc49-4f5f-b5df-920619b435b6.jpeg',title:"Trek Name B", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178490881-46c99a70-9b22-4789-af78-4e93e7d79ecb.jpeg',title:"Trek Name C", viewGallery:" view gallery"},
  ];
  const slideLeft =()=>{
  var slider = document.getElementById("slider");
  slider.scrollLeft= slider.scrollLeft - 300;
  }
  const slideRight =()=>{
    var slider = document.getElementById("slider");
    slider.scrollLeft= slider.scrollLeft + 300;
    }

  return (
    <>
    <div className='featured'>
            <h1 className='featured-text'>Beauty of <span className='red'>Pindari Glacier</span>Trek</h1>
            <p className='featured-text'>Located at an elevation of 3,353mts above sea level in the Bageshwar district of Uttarakhand,Himalayas, Pindari Glacier is a most accessible of all glacier treks.The trail becomes challenging and requires crossing mountain ridges, spider-walls, and streams. However, the unearthly beauty of the final point or Zero Point shall make you forget all the hardships.It passes through beautiful places of Dhakuri, Dwali, and Phurkia making it a eye candy.</p>
            <div className='container'>
                <img className='span-3 image-grid-row-2' src= 'https://user-images.githubusercontent.com/103182581/178490874-534d5aaf-dc7e-4979-8296-fdede661d94f.jpeg' alt='' />
                <img src= 'https://user-images.githubusercontent.com/103182581/178490799-c5f21e37-226b-44a8-a4f4-963a239863d6.jpeg' alt='' />
                <img src='https://user-images.githubusercontent.com/103182581/178490810-dc43b1e8-8c7d-4286-b206-706848c350f4.jpeg' alt='' />
                <img src='https://user-images.githubusercontent.com/103182581/178490814-7fd1bdea-8a14-4386-a160-584aadcb21dd.jpeg' alt='' />
                <img src='https://user-images.githubusercontent.com/103182581/178490825-ac1b6a15-0d0f-4ae7-9e7e-6fd150648f43.jpeg' alt='' />
            </div>
    </div>

    <div id="body">

<div id="Slider-Container">

       <MdChevronLeft size={40} className="slider-icon left" onClick={slideLeft}/>
       <div id="slider">
          { slides.map((slide,index)=>{
            return(
                <div className="slider-card" key={index}>
                    <div className="slider-card-image" style={{backgroundImage : `url(${slide.image})`}}></div>

                </div>
            )
          })

          }
       </div>
       <MdChevronRight size={40} className="slider-icon right" onClick={slideRight}/>

      </div>

    </div>

  </>

  )
}

export default Pindari;