import React from "react";
import "./Template.css";
import { MdChevronLeft,MdChevronRight} from 'react-icons/md';




const Brahmatal = (props) => {
  const slides = [{image: 'https://user-images.githubusercontent.com/103182581/178499315-93c78112-d4d1-42d0-a9bd-f5ca67c1c20e.jpeg',title:"Trek Name A", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178499306-364ae7f0-667d-4c11-ab88-4d132451ad7e.jpeg' ,title:"Trek Name A", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178499303-7ade7e20-32b5-4667-923b-cc607d103978.jpeg' ,title:"Trek Name A", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178499293-b9cfb06d-ab96-4a3c-bf3c-01052b731301.jpeg',title:"Trek Name B", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178499288-0a499c0a-d418-409b-98c4-3ce3ad0e0eda.jpeg',title:"Trek Name C", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178499280-ddcf93c4-0ce9-43d3-9ccd-c0869da367e1.jpeg',title:"Trek Name D", viewGallery:" view gallery"},
  ];
  const slideLeft =()=>{
  var slider = document.getElementById("slider");
  slider.scrollLeft= slider.scrollLeft - 300;
  }
  const slideRight =()=>{
    var slider = document.getElementById("slider");
    slider.scrollLeft= slider.scrollLeft + 300;
    }

  return (
    <>
    <div className='featured'>
            <h1 className='featured-text'>Beauty of <span className='red'>Bramha Tal Trek</span></h1>
            <p className='featured-text'>Brahmatal trek is devoted to Lord Brahma. It is beautifully positioned amidst the Himalayas and covered with blankets of snow. The trail allows you to see the centuries-old forest of Oak and Rhododendron. Walking in meadows besides Brahamatal Ridge you can see many sites of Roopkund Trek. A glance at the Garhwal Mountains in the distance on a clear day will totally melt your heart.</p>
            <div className='container'>
                <img className='span-3 image-grid-row-2' src= 'https://user-images.githubusercontent.com/103182581/178499212-fe054090-e2e8-4d80-b701-75bc55562f6e.jpeg' alt='' />
                <img src= 'https://user-images.githubusercontent.com/103182581/178499216-00c373c9-a09d-4fd2-9692-86f3f8cb6c41.jpeg' alt='' />
                <img src='https://user-images.githubusercontent.com/103182581/178499224-6d14772b-4150-4e3f-a2e4-397bdeccb46a.jpeg' alt='' />
                <img src='https://user-images.githubusercontent.com/103182581/178499254-882b63cf-ce88-4389-9f88-4b8ae67af674.jpeg' alt='' />
                <img src='https://user-images.githubusercontent.com/103182581/178499269-b63bcaf0-4739-4c9c-a8aa-8eacd4a7c098.jpeg' alt='' />
            </div>
    </div>

    <div id="body">

<div id="Slider-Container">

       <MdChevronLeft size={40} className="slider-icon left" onClick={slideLeft}/>
       <div id="slider">
          { slides.map((slide,index)=>{
            return(
                <div className="slider-card" key={index}>
                    <div className="slider-card-image" style={{backgroundImage : `url(${slide.image})`}}></div>

                </div>
            )
          })

          }
       </div>
       <MdChevronRight size={40} className="slider-icon right" onClick={slideRight}/>

      </div>

    </div>

  </>

  )
}

export default Brahmatal;