import React from "react";
import "./Template.css";
import { MdChevronLeft,MdChevronRight} from 'react-icons/md';




const Baradasar = (props) => {
  const slides = [{image: 'https://user-images.githubusercontent.com/103182581/178456676-c762b670-01f0-46b3-a596-7bf1bf1155bc.jpeg',title:"Trek Name A", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178456809-b9618dec-1003-4a18-9b71-089b52948ba7.jpeg' ,title:"Trek Name A", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178456726-1ba33a0e-c815-4909-99de-efbe1a8a4d69.jpeg' ,title:"Trek Name A", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178456742-cc484aeb-47b3-49d1-b31a-3e38083f130b.jpeg',title:"Trek Name B", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178456789-2a76bd37-9c50-4e93-8964-b502f1319877.jpeg',title:"Trek Name C", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178456796-2968dba3-3272-4497-89d5-23944f6d143e.jpeg',title:"Trek Name D", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178456801-cb786a6d-ae09-42ff-ae09-b9cfb63380cc.jpeg',title:"Trek Name A", viewGallery:" view gallery"},
  ];
  const slideLeft =()=>{
  var slider = document.getElementById("slider");
  slider.scrollLeft= slider.scrollLeft - 300;
  }
  const slideRight =()=>{
    var slider = document.getElementById("slider");
    slider.scrollLeft= slider.scrollLeft + 300;
    }

  return (
    <>
    <div className='featured'>
            <h1 className='featured-text'>Beauty of <span className='red'>Bardarsar Trek</span></h1>
            <p className='featured-text'>Baraadsar Lake Trek lies amidst high ridges at an altitude of 4,300 meters.Baraadsar lake considered to be of spiritual power is situated between valleys of Rupin and Supin.Trail has amazing beautiful campsites passing through dense forests.The trail is exhilarating and it takes you through alluring meadows and high ridges.The views from summit are enthralling and  you get panoramic views from there of snow covered peaks.</p>
            <div className='container'>
                <img className='span-3 image-grid-row-2' src= 'https://user-images.githubusercontent.com/103182581/178456591-68121a4b-0d6f-4e2b-b89f-ab44cc11236b.jpeg' alt='' />
                <img src= 'https://user-images.githubusercontent.com/103182581/178456600-d40e1f26-fc5e-4291-9cb9-117296ce99ba.jpeg' alt='' />
                <img src='https://user-images.githubusercontent.com/103182581/178456610-63f90d39-af22-4f33-9c49-d47a8814a6b2.jpeg' alt='' />
                <img src='https://user-images.githubusercontent.com/103182581/178456623-5e21a0bd-49f5-497c-ba89-0a3ecfeb391f.jpeg' alt='' />
                <img src='https://user-images.githubusercontent.com/103182581/178456632-9847db5a-a181-49ef-a83e-f5fa5f436a3b.jpeg' alt='' />
            </div>
    </div>

    <div id="body">

<div id="Slider-Container">

       <MdChevronLeft size={40} className="slider-icon left" onClick={slideLeft}/>

       <div id="slider">
          { slides.map((slide,index)=>{
            return(
                <div className="slider-card" key={index}>
                    <div className="slider-card-image" style={{backgroundImage : `url(${slide.image})`}}></div>

                </div>
            )
          })

          }
       </div>

       <MdChevronRight size={40} className="slider-icon right" onClick={slideRight}/>

      </div>

    </div>

  </>

  )
}

export default Baradasar;