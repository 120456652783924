import React, { useEffect, useState } from "react";
import { BsFillPersonFill, BsFillTelephoneFill } from "react-icons/bs";
// import "./SummerTrekRegistration.css";
import { IoMdMailUnread } from "react-icons/io";
import { MdContactPage, MdEngineering } from "react-icons/md";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import FormModal from "../FormModal/FormModal";
import s from "./SummerTrek.module.css";

function SummerTrekRegistration() {
  let initial = {
    name: "",
    rollNo: "",
    email: "",
    phone: "",
    emergencyPhone: "",
    enrollment: "",
    yearOfStudy: "",
    hall: "",
    block_room_no: "",
    funSkill: "",
    instaID: "",
    returnBack: false,
  };

  const [input, setInput] = useState(initial);
  const [otherYear, setOtherYear] = useState("");
  const [loading, setLoading] = useState(false);

  const openLoading = () => setLoading(true);
  const closeLoading = () => setLoading(false);

  const validateData = (body) => {
    return (
      body.email != "" &&
      body.name != "" &&
      body.rollNo != "" &&
      body.email != "" &&
      body.phone != "" &&
      body.emergencyPhone != "" &&
      body.enrollment != "" &&
      body.yearOfStudy != "" &&
      body.hall != "" &&
      body.block_room_no != "" &&
      body.funSkill != ""
    );
  };

  useEffect(() => {
    if (input.yearOfStudy == "other") {
      setInput((prev) => ({ ...prev, yearOfStudy: otherYear }));
    }
  }, [input]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    openLoading();

    let body = { ...input };
    let pattern = /^\w+[\+\.\w-]*@([\w-]+\.)*\w+[\w-]*\.([a-z]{2,4}|\d+)$/i;
    let result = pattern.test(input.email);
    if (!result) {
      closeLoading();
      toast.error("Please enter a valid email address");
      return;
    }

    if (validateData(body) && input.funSkill != "") {
      axios
        .post("https://tads-iitkgp.onrender.com/api/summer-trek", body)
        .then((res) => {
          if (res.data) {
            setInput(initial);
          }
        });
    } else {
      closeLoading();
      toast.error("Fill the Form Completely");
    }
  };

  return (
    <main id="summer-trek" className={s.summerTrek}>
      <div className={s.wrapper}>
        <div className={s.title}>
          <h1>
            Sar Pass Trek 
            Registration Form
          </h1>
          <p>
            Do Checkout the{" "}
            <a
              target="_blank"
              style={{ color: "crimson" }}
              href="https://tinyurl.com/sar-pass-itinerary"
            >
              Itinerary
            </a>
          </p>
          <br />
          <p>
            Fill this <a href="https://forms.gle/t4UDb2DmJgQ6cJRv8" target="_blank" rel="noopener noreferrer">Registration Form</a>
          </p>
        </div>
        
      </div>
      <ToastContainer
        className="toast"
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {loading && <FormModal />}
    </main>
  );
}

export default SummerTrekRegistration;
