import React from "react";
import "./Template.css";
import { MdChevronLeft,MdChevronRight} from 'react-icons/md';





const Digah = (props) => {
  const slides = [
    {image: 'https://user-images.githubusercontent.com/103182581/178493746-384d4a08-df0f-474a-8aa8-ae887e0d74e6.jpeg',title:"Trek Name A", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178493750-39693551-9cf5-407b-bab2-6892543199f4.jpeg' ,title:"Trek Name A", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178493763-b23fa7d9-d44b-41ed-8d0d-810dd5383386.jpeg' ,title:"Trek Name A", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178493770-6dbe82c1-97cf-41e1-98fe-fdee4a4f80ae.jpeg',title:"Trek Name B", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178493775-03ebd313-3918-4827-9b3c-bb8338347883.jpeg',title:"Trek Name C", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178493787-2330a9cb-8fde-4f40-98e9-476a7625c8e6.jpeg',title:"Trek Name D", viewGallery:" view gallery"},
  ];
  const slideLeft =()=>{
  var slider = document.getElementById("slider");
  slider.scrollLeft= slider.scrollLeft - 300;
  }
  const slideRight =()=>{
    var slider = document.getElementById("slider");
    slider.scrollLeft= slider.scrollLeft + 300;
    }

  return (
    <>
    <div className='featured'>
            <h1 className='featured-text'>Beauty of <span className='red'>Digha Trip</span></h1>
            <p className='featured-text'>Digha is a town in coastal region of West Bengal. The Bay of Bengal is visible from the dune-backed Old Digha Sea Beach. Sharks, rays, and sea snakes can be found at the Marine Aquarium and Regional Centre (MARC), which is located nearby. Leafy Amarabati Park surrounds a lake to the west, inland from sandy New Digha Sea Beach. The white-domed Chandaneswar Shiv Temple, is one of the most visited spiritual sight of the region.</p>
            <div className='container'>
                <img className='span-3 image-grid-row-2' src= 'https://user-images.githubusercontent.com/103182581/178493700-31d76c16-a36f-4468-a2e1-12565d0fd933.jpeg' alt='' />
                <img src= 'https://user-images.githubusercontent.com/103182581/178493709-22978daa-11fa-401c-9e8e-b0ef52092551.jpeg' alt='' />
                <img src='https://user-images.githubusercontent.com/103182581/178493884-2f4e894a-f29a-41d1-9515-b5f6eb9677a0.jpeg' alt='' />
                <img src='https://user-images.githubusercontent.com/103182581/178493721-0dca4922-e041-438b-aeac-a0d4fcb71f2f.jpeg' alt='' />
                <img src='https://user-images.githubusercontent.com/103182581/178493734-8b5631b1-6658-4d6d-8c9b-f59ea01e6000.jpeg' alt='' />
            </div>
    </div>

    <div id="body">

<div id="Slider-Container">

       <MdChevronLeft size={40} className="slider-icon left" onClick={slideLeft}/>
       <div id="slider">
          { slides.map((slide,index)=>{
            return(
                <div className="slider-card" key={index}>
                    <div className="slider-card-image" style={{backgroundImage : `url(${slide.image})`}}></div>

                </div>
            )
          })

          }
       </div>
       <MdChevronRight size={40} className="slider-icon right" onClick={slideRight}/>

      </div>

    </div>

  </>

  )
}

export default Digah;