import React from "react";
import "./Template.css";
import { MdChevronLeft,MdChevronRight} from 'react-icons/md';





const PangarchulaPeak = (props) => {
  const slides = [{image: 'https://user-images.githubusercontent.com/103182581/178453695-0b898819-e91b-45a4-ace1-fd34bd15dce4.jpeg',title:"Trek Name A", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178453712-7e8c85ee-adb2-4f66-8845-ce105cdeb01b.jpeg' ,title:"Trek Name A", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178453717-39c1c63d-fcaa-452b-90f3-f830686750a2.jpeg' ,title:"Trek Name A", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178453723-b8cc8d5d-53cf-4391-8cda-68135b44b337.jpeg',title:"Trek Name B", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178453736-ac3de504-dda8-40c0-8036-b7ded65f4a22.jpeg',title:"Trek Name C", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178453830-7a55918f-d928-48ab-8e49-0720ab88e862.jpeg',title:"Trek Name D", viewGallery:" view gallery"},
  ];
  const slideLeft =()=>{
  var slider = document.getElementById("slider");
  slider.scrollLeft= slider.scrollLeft - 300;
  }
  const slideRight =()=>{
    var slider = document.getElementById("slider");
    slider.scrollLeft= slider.scrollLeft + 300;
    }

  return (
    <>
    <div className='featured'>
            <h1 className='featured-text'>Beauty of <span className='red'>Pangarchula Peak Trek</span></h1>
            <p className='featured-text'>The summit point of the Pangarchulla Peak trek is situated at a challenging altitude of 15,069 ft. The trek takes us through pilgrim veils of Uttarakhand along with notable sites including terraced famrlands and sloopy roofed houses providing beautiful scenery.</p>
            <div className='container'>
                <img className='span-3 image-grid-row-2' src= 'https://user-images.githubusercontent.com/103182581/178453635-36081226-697b-4392-a6d5-61367fa099eb.jpeg' alt='' />
                <img src= 'https://user-images.githubusercontent.com/103182581/178453641-0a317c2b-724a-44ec-ac42-ef7b1de54430.jpeg' alt='' />
                <img src= 'https://user-images.githubusercontent.com/103182581/178453651-6b47595c-aecb-4884-a454-ffad6c03bdbf.jpeg'alt='' />
                <img src='https://user-images.githubusercontent.com/103182581/178453671-e47744b4-2cf2-4306-a464-af4c2aa4e5e1.jpeg' alt='' />
                <img src='https://user-images.githubusercontent.com/103182581/178453689-92bf13de-b4c6-4ccf-bc28-92bef641c6c2.jpeg' alt='' />
            </div>
    </div>

    <div id="body">

<div id="Slider-Container">

       <MdChevronLeft size={40} className="slider-icon left" onClick={slideLeft}/>
       <div id="slider">
          { slides.map((slide,index)=>{
            return(
                <div className="slider-card" key={index}>
                    <div className="slider-card-image" style={{backgroundImage : `url(${slide.image})`}}></div>

                </div>
            )
          })

          }
       </div>
       <MdChevronRight size={40} className="slider-icon right" onClick={slideRight}/>

      </div>

    </div>

  </>

  )
}

export default PangarchulaPeak;