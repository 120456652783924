import React from "react";
import "./Template.css";
import { MdChevronLeft,MdChevronRight} from 'react-icons/md';




const HamptaPass = (props) => {
  const slides = [{image: 'https://user-images.githubusercontent.com/103182581/178492669-943be996-0341-48f9-b2e4-f676d577ae7c.jpeg',title:"Trek Name A", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178492698-4b9fdf05-0cb0-4f0b-ad25-50a1f0f4b097.jpeg' ,title:"Trek Name A", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178492705-54477758-ad3b-4295-a79a-4a5787c7f73b.jpeg',title:"Trek Name B", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178492709-7909a84b-1ebc-40ef-9ccb-f464b537949d.jpeg',title:"Trek Name C", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178492715-f1a664ce-d170-47d6-b866-3efc01d3342e.jpeg',title:"Trek Name D", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178492721-79a3227f-079a-4aef-a694-39406364b674.jpeg',title:"Trek Name A", viewGallery:" view gallery"},
  ];
  const slideLeft =()=>{
  var slider = document.getElementById("slider");
  slider.scrollLeft= slider.scrollLeft - 300;
  }
  const slideRight =()=>{
    var slider = document.getElementById("slider");
    slider.scrollLeft= slider.scrollLeft + 300;
    }

  return (
    <>
    <div className='featured'>
            <h1 className='featured-text'>Beauty of <span className='red'>Hampta Pass Trek</span></h1>
            <p className='featured-text'>Hampta Pass situated at an elevation of 14000 ft is one of rare pass crossing treks with opposite views on the both side.On one side is the lush green valley of Kullu — with forests, grasslands and what not whereas the other side is the arid landscape of Lahaul, with almost no vegetation.</p>
            <div className='container'>
                <img className='span-3 image-grid-row-2' src= 'https://user-images.githubusercontent.com/103182581/178492662-ccea9b96-cb0f-4671-a04e-195e8dd23043.jpeg' alt='' />
                <img src= 'https://user-images.githubusercontent.com/103182581/178492672-a64d3783-93ae-411e-ae8a-d036d013762e.jpeg' alt='' />
                <img src='https://user-images.githubusercontent.com/103182581/178492674-0f3cb17a-6234-4c77-b729-3738372684ae.jpeg' alt='' />
                <img src='https://user-images.githubusercontent.com/103182581/178492689-b34e18f6-6e31-4020-8b95-f5dc8e74675c.jpeg' alt='' />
                <img src='https://user-images.githubusercontent.com/103182581/178492693-cfd401ea-d7c5-49b9-a415-b3fc3dadf8c8.jpeg' alt='' />
            </div>
    </div>

    <div id="body">

<div id="Slider-Container">

       <MdChevronLeft size={40} className="slider-icon left" onClick={slideLeft}/>
       <div id="slider">
          { slides.map((slide,index)=>{
            return(
                <div className="slider-card" key={index}>
                    <div className="slider-card-image" style={{backgroundImage : `url(${slide.image})`}}></div>

                </div>
            )
          })

          }
       </div>
       <MdChevronRight size={40} className="slider-icon right" onClick={slideRight}/>

      </div>

    </div>

  </>

  )
}

export default HamptaPass;