import LandingPage from "./Components/LandingPage/LandingPage";
import ContactPage from "./Components/ContactPage/ContactPage";
import AboutPage from "./Components/AboutPage/AboutPage";
import BlogPage from "./Components/BlogPage/BlogPage";
import Navbar from "./Components/Navbar/Navbar";
import Loader from "./Components/Loader/Loader";
import AdvWeekend from "./Components/Events/AdvWeekend/AdvWeekend";
import CycleTrip from "./Components/Events/CycleTrip/CycleTrip";
import RappelingZipline from "./Components/Events/RapellingZipline/RapellingZipline";
import TreksTrips from "./Components/Events/Treks-Trips/TreksTrips";
import GuestLecture from "./Components/Events/GuestLecture/GuestLecture";
import ESTournaments from "./Components/Events/ESTournaments/ESTournaments";
import TeamPageComponent from "./Components/TeamPage/TeamPageComponent";
// import FresherSelection from "./Components/FresherSelection/FresherSelection";
import AdventureWeekend from "./Components/AW1/AdventureWeekend";

import "react-toastify/dist/ReactToastify.css";

import { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import NotFoundPage from "./Components/NotFoundPage/NotFoundPage";
import SummerTrekRegistration from "./Components/SummerTrekRegistration/SummerTrekRegistration";
import Slot from "./Components/Slot/Slot";

const TRANSPARENT_NAVBAR_ROUTES = [
  "/",
  "/about-us",
  "/events/treks-and-trips",
  "/events/adventure-weekend",
  "/events/rappelling-and-zipline",
  "/events/guest-lecture",
  "/events/esports-tournament",
];

function App() {
  const [isLoaded, setIsLoaded] = useState(false);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="App">
      <Loader isLoaded={isLoaded} setIsLoaded={setIsLoaded} />
      <Navbar
        type={
          TRANSPARENT_NAVBAR_ROUTES.includes(location.pathname)
            ? "fixed"
            : "sticky"
        }
      />
      <Routes>
        <Route path="/" element={<LandingPage />} exact />
        <Route path="/contact-us" element={<ContactPage />} exact />
        <Route path="/about-us" element={<AboutPage />} exact />
        <Route path="/blog" element={<BlogPage />} exact />
        <Route path="/our-team" element={<TeamPageComponent />} exact />
        <Route path="/events/guest-lecture" element={<GuestLecture />} exact />
        <Route
          path="/events/esports-tournament"
          element={<ESTournaments />}
          exact
        />
        <Route
          path="/events/adventure-weekend"
          element={<AdvWeekend />}
          exact
        />
        <Route path="/events/cycle-trip" element={<CycleTrip />} exact />
        <Route
          path="/events/rappelling-and-zipline"
          element={<RappelingZipline />}
          exact
        />
        <Route
          path="/trek-registration"
          element={<SummerTrekRegistration />}
          exact
        />
        <Route path="/events/treks-and-trips" element={<TreksTrips />} exact />
        <Route path="*" element={<NotFoundPage />} />
        {/*
          <Route path="/fresher-selection" element={<FresherSelection />} exact />
          */}
{/* 
        <Route path='/fresher-selection' component={() => {
            window.location.href = 'http://bit.ly/ASM-Selection-23';
            return <>Will redirect in 5 seconds...</>;
        }}/> */}

        <Route
          path="/adventure-weekend"
          element={<AdventureWeekend />}
          exact
        ></Route>
      </Routes>
    </div>
  );
}

export default App;
