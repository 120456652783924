import { useState } from "react";
import "./ContactPage.css";
import mail from "./img/mail_b.png";
import phone from "./img/phone_b.png";
import g_loc from "./img/google-maps.png";

function ContactPage() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const [load, setLoad] = useState(false);
  const [fail, setFail] = useState(false);
  const [success, setSuccess] = useState(false);

  const getChangeHandler = (setter) => (e) => {
    setter(e.target.value);
  };

  const apiCall = async () => {
    setLoad(true);
    let date = new Date();
    let current_date = `${date.toLocaleDateString(
      "en-IN"
    )} at ${date.toLocaleTimeString()}`;
    let mailBody = {
      name: await name,
      subject: await subject,
      date: current_date,
      message: await message,
      email: await email,
    };
    let res = await fetch("http://localhost:5000/api/contact", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(mailBody),
    });
    if (res.status === 200) {
      setLoad(false);
      setSuccess(true);
      setName("");
      setEmail("");
      setSubject("");
      setMessage("");
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    } else {
      setLoad(false);
      setFail(true);
      setTimeout(() => {
        setFail(false);
      }, 5000);
    }
  };

  const onSubmit = (e) => {
    setLoad(true);
    e.preventDefault();
    apiCall();
  };

  return (
    <main id="contact-us">
      <div className="title">
        <h2>Contact Us</h2>
      </div>
      <div className="box">
        <div className="contact form">
          <h3>Send us a Message</h3>
          <form onSubmit={onSubmit}>
            <div className="formBox">
              <div className="inrow">
                <div className="row50">
                  <div className="inputBox">
                    <span>Name</span>
                    <input
                      id="name"
                      type="text"
                      placeholder="Enter your name"
                      required="required"
                      value={name}
                      onChange={getChangeHandler(setName)}
                    />
                  </div>
                </div>
                <div className="row50">
                  <div className="inputBox">
                    <span>Email</span>
                    <input
                      type="email"
                      placeholder="Enter your email-id"
                      required="required"
                      value={email}
                      onChange={getChangeHandler(setEmail)}
                    />
                  </div>
                </div>
              </div>
              <br />
              <div className="row100">
                <div className="inputBox">
                  <span>Subject</span>
                  <input
                    type="text"
                    placeholder="Enter the subject"
                    required="required"
                    value={subject}
                    onChange={getChangeHandler(setSubject)}
                  />
                </div>
              </div>
              <div className="row100">
                <div className="inputBox">
                  <span>Message</span>
                  <textarea
                    placeholder="Write your message here..."
                    value={message}
                    onChange={getChangeHandler(setMessage)}
                  />
                </div>
              </div>
              <br />
              <div className="row100">
                <div className="inputBox">
                  <button className="sendBtn">
                    {load ? "Sending..." : "Send"}
                  </button>
                </div>
                {fail && (
                  <div className="alert alert-fail">
                    <p>Internal Error Occurred</p>
                    {/* <button onClick={closeFail}>X</button> */}
                  </div>
                )}
                {success && (
                  <div className="alert alert-success">
                    <p>Your Message sent successfully</p>
                    {/* <button onClick={closeSuccess}>X</button> */}
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
        <div className="contact info">
          <h3>Contact Info</h3>
          <div className="infoBox">
            <div> 
              <span>
                <img src={g_loc} alt="location"/>
              </span> 
             <p>
                Technology Adventure Society, Technology Students'
                Gymkhana,Indian Institute of Technology, Kharagpur (721302)
              </p> 
            </div> 
            <div>
              <span>
                <img name="mail" src={mail} alt="mail" />
              </span>
              <a href="mailto:tads.iitkgp@gmail.com">tads.iitkgp@gmail.com</a>
            </div>
            <div>
              <span>
                <img className="phone" src={phone} alt="phone" />
              </span>
              <a href="tel:+91-7030067932">+91-7030067932</a>
            </div>
          </div>
        </div>
        <div className="contact map">
          <iframe
            title="Map Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3690.923541847923!2d87.30034111490617!3d22.31873118531406!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a1d4407422c1675%3A0xa2d9d15d09ca4c4!2sTechnology%20Students'%20Gymkhana!5e0!3m2!1sen!2sin!4v1657095701227!5m2!1sen!2sin"
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />{" "}
        </div>
      </div>
    </main>
  );
}

export default ContactPage;
