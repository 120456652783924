import React from "react";
import { Link } from "react-router-dom";
import "./AboutPage.css";
import AdventureWeekend from "./img/adventure_weekend.webp";
import CycleTrip from "./img/cycle_trip1.webp";
import Rapelling from "./img/rapelling1.webp";
import Trek from "./img/trek1.webp";

import Slider from "react-slick";

export default function AboutPage() {
  var settings = {
    accessibility: false,
    arrows: false,
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: false,
    centerPadding: "50px",
    useCSS: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 4000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <main id="about-us">
      <div className="part1 start">
        <div className="bgimg"></div>
        <div className="content">
          <p className="motto">
            FOR DOERS,
            <br />
            NOT VIEWERS
          </p>
          <p className="para1">
            Founded in the year 2008, with a vision to promote adventurous
            sports and activities in the young and enthusiastic minds.We are
            committed to assisting you in achieving your wildest adventures as
            you build new friendships, challenge your limits, unwind your
            spirits, and learn about the natural world around you!
            <br />
            <br />
            Everyone has heard of extreme sports like Mountaineering, Rock
            Climbing, Mountain Biking, Hang Gliding, Bungee jumping, but only
            few take the leap into the thrilling world of adventure sports.Why
            regret not having tried it at all? Drop the fear and take the
            risk.TAdS is here to ensure you take that extra step and never
            regret it.
            <br />
            <br />
            You do not have to worry about arrangements, technical assistance &
            equipment; all you need is the strength to face your fears & the
            will to achieve the aim. For the rest, there is TAdS! Let the
            adventure begin!!!
          </p>
        </div>
      </div>
      <div className="part2">
        <div className="h2">Major Events</div>
        <div className="container">
          <Slider {...settings}>
            <div>
              <div className="card">
                <div className="card_img">
                  <img className="img2" src={Trek} alt="Trek" />
                </div>
                <div className="card_content">
                  <div>
                    <h4>Treks and Trips</h4>
                    <p className="">
                      It’s not the mountain we conquer, but the fear within
                    </p>
                  </div>
                  <Link to="/events/treks-and-trips">
                    <button
                      className="button"
                      style={{ verticalAlign: "middle" }}
                    >
                      <span>Explore the Event</span>
                    </button>
                  </Link>
                </div>
              </div>
            </div>

            <div>
              <div className="card">
                <div className="card_img">
                  <img className="img1" src={Rapelling} alt="Rappelling" />
                </div>
                <div className="card_content">
                  <div>
                    <h4>Rapelling and Zipline</h4>
                    <p className="">
                      Drop the fear and take the risk. You will never regret it.
                    </p>
                  </div>
                  <Link to="/events/rappelling-and-zipline">
                    <button
                      className="button"
                      style={{ verticalAlign: "middle" }}
                    >
                      <span>Explore the Event</span>
                    </button>
                  </Link>
                </div>
              </div>
            </div>

            <div>
              <div className="card">
                <div className="card_img">
                  <img className="img2" src={CycleTrip} alt="Cycle Trip" />
                </div>
                <div className="card_content">
                  <div>
                    <h4>Cycle Trip</h4>
                    <p className="">
                      Take a ride and explore the beauty of our campus!
                    </p>
                  </div>
                  <Link to="/events/cycle-trip">
                    <button
                      className="button"
                      style={{ verticalAlign: "middle" }}
                    >
                      <span>Explore the Event</span>
                    </button>
                  </Link>
                </div>
              </div>
            </div>

            <div>
              <div className="card">
                <div className="card_img">
                  <img
                    className="img1"
                    src={AdventureWeekend}
                    alt="Adventure Weekend"
                  />
                </div>
                <div className="card_content">
                  <div>
                    <h4>Adventure Weekend</h4>
                    <p>
                      Adventure is an attitude, you get to experience it here!
                    </p>
                  </div>
                  <Link to="/events/adventure-weekend">
                    <button
                      className="button"
                      style={{ verticalAlign: "middle" }}
                    >
                      <span>Explore the Event</span>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </main>
  );
}
