import React from "react";
import { Link } from "react-router-dom";
import s from "./NotFoundPage.module.css";

export default function NotFoundPage() {
  return (
    <div className={s.main}>
      <div>
        <h1>Oops!</h1>
        <h2>The Link you tried to open was not found</h2>
        <p>Here are some helpful links:</p>
        <div className={s.linkContainer}>
          <Link to="/">Home</Link>
          <Link to="/blog">Blog</Link>
          <Link to="/about-us">About Us</Link>
          <Link to="/our-team">Our Team</Link>
          <Link to="/contact-us">Contact Us</Link>
        </div>
      </div>
    </div>
  );
}
