import React from "react";
import "./Template.css";
import { MdChevronLeft,MdChevronRight} from 'react-icons/md';





const Dodital = (props) => {
  const slides = [
    {image: 'https://user-images.githubusercontent.com/103182581/178494921-ea2f90b8-0842-4e14-bdbd-fb23666e7299.jpeg',title:"Trek Name A", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178494928-7aaba474-0991-4861-85ea-1556ea793c09.jpeg' ,title:"Trek Name A", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178494929-d41acb9e-a882-4cce-974e-f5ab717b16d4.jpeg' ,title:"Trek Name A", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178494934-0474cd3b-7b6a-4cab-92a0-3d36bf8aab5a.jpeg',title:"Trek Name B", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178494936-aa3e1100-6a64-4e57-a269-be73db1c9416.jpeg',title:"Trek Name C", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178494940-3bf5757a-408f-469d-a4ed-f724b08e95f6.jpeg',title:"Trek Name D", viewGallery:" view gallery"},

  ];
  const slideLeft =()=>{
  var slider = document.getElementById("slider");
  slider.scrollLeft= slider.scrollLeft - 300;
  }
  const slideRight =()=>{
    var slider = document.getElementById("slider");
    slider.scrollLeft= slider.scrollLeft + 300;
    }

  return (
    <>
    <div className='featured'>
            <h1 className='featured-text'>Beauty of <span className='red'>Dodital Darwa Trek</span></h1>
            <p className='featured-text'>Nestled high up in the mountains at an altitude of 3310 mts above sea level Dodital trek is another must  trek. Known as one of the most beautiful treks in North India this trek has been attracting travelers from far and wide. This trek is named after the rare Himalayan trout which is known as Dodi in the local language.The Dodital Lake is a beautiful campsite surrounded by pine and oak trees.</p>
            <div className='container'>
                <img className='span-3 image-grid-row-2' src= 'https://user-images.githubusercontent.com/103182581/178494900-86e38309-7f7e-410e-9280-0be1cdb4cb90.jpeg' alt='' />
                <img src= 'https://user-images.githubusercontent.com/103182581/178494902-be0acb91-cd09-4435-8aed-57d134ded6e1.jpeg' alt='' />
                <img src='https://user-images.githubusercontent.com/103182581/178494911-a81fee1e-5cfa-4577-ae79-eae5926cff69.jpeg' alt='' />
                <img src='https://user-images.githubusercontent.com/103182581/178494916-eb8c279e-d420-4bb3-b21f-b35e8a03c903.jpeg' alt='' />
                <img src='https://user-images.githubusercontent.com/103182581/178494920-1341f51a-117f-42b2-b85e-4cf6b9f68fbb.jpeg' alt='' />
            </div>
    </div>

    <div id="body">

<div id="Slider-Container">

       <MdChevronLeft size={40} className="slider-icon left" onClick={slideLeft}/>
       <div id="slider">
          { slides.map((slide,index)=>{
            return(
                <div className="slider-card" key={index}>
                    <div className="slider-card-image" style={{backgroundImage : `url(${slide.image})`}}></div>

                </div>
            )
          })

          }
       </div>
       <MdChevronRight size={40} className="slider-icon right" onClick={slideRight}/>

      </div>

    </div>

  </>

  )
}

export default Dodital;