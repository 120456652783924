import { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faClose } from "@fortawesome/free-solid-svg-icons";

import NavbarSubmenu from "./NavbarSubmenu/NavbarSubmenu";
import "./Navbar.css";
import LogoWhite from "./img/logo-white.png";
import { Link } from "react-router-dom";
// import AdventureWeekend from "../AW1/AdventureWeekend";

/**
 *
 * @param {{type: 'sticky' | 'fixed'}} props
 * @returns
 */
const Navbar = (props) => {
  // NAVBAR SCROLL EFFECT
  const [isNavbarTransparent, setIsNavbarTransparent] = useState(true);
  const dropdownBtnRef = useRef();
  const navbarLinksRef = useRef();

  useEffect(() => {
    const onScroll = () => {
      const atTop = document.scrollingElement.scrollTop === 0;

      if (atTop ^ isNavbarTransparent) {
        setIsNavbarTransparent(!isNavbarTransparent);
      }
    };

    document.addEventListener("scroll", onScroll);

    return () => {
      document.removeEventListener("scroll", onScroll);
    };
  });
  // NAVBAR SCROLL EFFECT

  // NAVBAR DROPDOWN
  const [isDroppedDown, setIsDroppedDown] = useState(false);

  const onClickDropdown = () => {
    if (!isDroppedDown) setIsDroppedDown(true);
    else {
      navbarLinksRef.current.style = "";
      setTimeout(() => setIsDroppedDown(false), 200);
    }
  };

  useEffect(() => {
    const onWindowClick = (e) => {
      if (
        dropdownBtnRef.current !== null &&
        !dropdownBtnRef.current.contains(e.target) &&
        !document.querySelector(".navbar-submenu-trigger").contains(e.target)
      ) {
        setIsDroppedDown(false);
      }
    };

    // If the item is active (ie open) then listen for clicks
    if (isDroppedDown) {
      window.addEventListener("click", onWindowClick);
    }

    return () => {
      window.removeEventListener("click", onWindowClick);
    };
  }, [isDroppedDown]);

  useEffect(() => {
    if (navbarLinksRef.current !== null) {
      if (isDroppedDown) {
        // navbarLinksRef.current.style.setProperty('display', 'flex');
        setTimeout(
          () => navbarLinksRef.current.style.setProperty("opacity", "1"),
          10
        );
      }
    }
  }, [isDroppedDown]);
  // NAVBAR DROPDOWN

  return (
    <nav
      className={[
        `navbar`,
        isNavbarTransparent && props.type !== "sticky" ? "transparent" : "",
        `navbar-${props.type ?? "fixed"}`,
      ].join(" ")}
    >
      <Link to="/" className="navbar-logo-link">
        <img src={LogoWhite} alt="TAdS Logo" className="navbar-logo" />
      </Link>

      <button
        className="navbar-dropdown-btn"
        onClick={onClickDropdown}
        ref={dropdownBtnRef}
      >
        <FontAwesomeIcon icon={isDroppedDown ? faClose : faBars} size="2x" />
      </button>
      <div
        className={["navbar-links", isDroppedDown ? "dropdown" : ""].join(" ")}
        ref={navbarLinksRef}
      >
      {/* <Link to="/adventure-weekend"><b><a className="navbar-link new" style={{color: "#fc1919",marginLeft:"3rem"}}>Adventure Weekend!</a></b></Link>
       */}
	    {/* <Link to='https://bit.ly/ASM-Selection-23' className="navbar-link" style={{color: "#fc1919"}}>FRESHER'S SELECTION!</Link> */}
      {/* <a href ="https://bit.ly/ASM-Selection-23" className="navbar-link" style={{color: "#fc1919"}}>FRESHER'S SELECTION!</a> */}
  <b>
      <span class="new">
      <a className="navbar-link" style={{color: "#fc1919", fontWeight: 900, fontSize: 20}} href="https://docs.google.com/forms/d/e/1FAIpQLSfeD34iyDfNueSZ_T02zaZqJh6a_E_SfGYIVILe4B_d1r80_w/viewform?usp=send_form" target="_blank">Freshers Selection!</a>
      </span>
      </b>
  
        {/* <Link
          to="/trek-registration"
          style={{ color: "#fc1919" }}
          className="navbar-link"
        >
          Summer Trek Registration
        </Link> */}

        {/* <Link
          to="/adventure-weekend"
          style={{ color: "#fc1919" }}
          className="navbar-link"
        >
          Adventure Weekend 2 Registration
        </Link> */}
        
        <Link to="/about-us" className="navbar-link">
          About Us
        </Link>
        <NavbarSubmenu
          title="Events"
          sublinks={[
            { title: "Treks & Trips", to: "/events/treks-and-trips" },
            { title: "Adventure Weekend", to: "/events/adventure-weekend" },
            {
              title: "Rapelling & Zipline",
              to: "/events/rappelling-and-zipline",
            },
            { title: "Cycle Trip", to: "/events/cycle-trip" },
            { title: "Guest Lecture", to: "/events/guest-lecture" },
            { title: "E-Sports Tournament", to: "/events/esports-tournament" },
          ]}
        />
        <Link to="/blog" className="navbar-link">
          Blog
        </Link>
        <Link to="/our-team" className="navbar-link">
          Our Team
        </Link>
        <Link to="/contact-us" className="navbar-link">
          Contact Us
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
