import React from "react";
import "./Template.css";
import { MdChevronLeft,MdChevronRight} from 'react-icons/md';




const ManaliTrek = (props) => {
  const slides = [{image: 'https://user-images.githubusercontent.com/103182581/178496183-91fcd4b7-6d4e-48da-8ba0-eba74dffe593.jpeg',title:"Trek Name A", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178496166-54a355b6-2c50-4cf3-85c2-c277b6516ee3.jpeg' ,title:"Trek Name A", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178496149-af51ac5c-311b-4281-96ea-c4fefb9e87e3.jpeg' ,title:"Trek Name A", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178496140-b17e2936-ddca-4f33-b11a-e95775b76e84.jpeg',title:"Trek Name B", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178496131-546544a2-afe5-4d30-866e-fd152d700cc2.jpeg',title:"Trek Name C", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178496093-8b7acfc1-4adf-4c0f-8726-8c1fc86af4cb.jpeg',title:"Trek Name D", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178496122-da888a83-9551-4017-b028-952de8605099.jpeg',title:"Trek Name A", viewGallery:" view gallery"},
];
  const slideLeft =()=>{
  var slider = document.getElementById("slider");
  slider.scrollLeft= slider.scrollLeft - 300;
  }
  const slideRight =()=>{
    var slider = document.getElementById("slider");
    slider.scrollLeft= slider.scrollLeft + 300;
    }

  return (
    <>
    <div className='featured'>
            <h1 className='featured-text'>Beauty of <span className='red'>Manali Trek</span></h1>
            <p className='featured-text'>One of the most popular hill stations in Himachal, Manali offers the most magnificent views of the Pir Panjal and the Dhauladhar ranges covered with snow for most parts of the year. Manali has many trekking options around it, making it a great base for exploring this side of Himalayas. River Beas provides great rafting options in the nearby town of Kullu. Adjoining Parvati river, lies the Parvati Valley with  Kasol, Manikaran, Tosh and small villages attracting travellers for longer stays.</p>
            <div className='container'>
                <img className='span-3 image-grid-row-2' src= 'https://user-images.githubusercontent.com/103182581/178496127-1fe097f5-64ed-4b00-abdc-cde698616223.jpeg' alt='' />
                <img src= 'https://user-images.githubusercontent.com/103182581/178496102-b8a88cd6-15da-4b49-83e5-7ed67b2d42f5.jpeg' alt='' />
                <img src='https://user-images.githubusercontent.com/103182581/178496107-a349fcf3-53ea-45e9-8095-f2abef72de80.jpeg' alt='' />
                <img src='https://user-images.githubusercontent.com/103182581/178496114-ff98b808-a098-4424-860c-fe17d3ea06a5.jpeg' alt='' />
                <img src='https://user-images.githubusercontent.com/103182581/178496122-da888a83-9551-4017-b028-952de8605099.jpeg' alt='' />
            </div>
    </div>

    <div id="body">

<div id="Slider-Container">

       <MdChevronLeft size={40} className="slider-icon left" onClick={slideLeft}/>
       <div id="slider">
          { slides.map((slide,index)=>{
            return(
                <div className="slider-card" key={index}>
                    <div className="slider-card-image" style={{backgroundImage : `url(${slide.image})`}}></div>

                </div>
            )
          })

          }
       </div>
       <MdChevronRight size={40} className="slider-icon right" onClick={slideRight}/>

      </div>

    </div>

  </>

  )
}

export default ManaliTrek;