import React from "react";
import "./Template.css";
import { MdChevronLeft,MdChevronRight} from 'react-icons/md';



const KauriPass = (props) => {
  const slides = [
  {image: 'https://user-images.githubusercontent.com/103182581/178497196-ed74acd2-8c1d-491e-a40a-8f2e6f09bb3e.jpeg' ,title:"Trek Name A", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178497200-e4b9fc03-bd9b-43aa-8509-a07dba427148.jpeg',title:"Trek Name B", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178497303-59fca18a-1069-4fb9-a543-49da240956db.jpeg',title:"Trek Name C", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178497215-bc11abb3-d3d6-4f07-8c20-edb6f2540686.jpeg',title:"Trek Name D", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178497660-53635c40-1812-479f-b69e-e731cd8d64b5.jpeg',title:"Trek Name A", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178497260-25cfe63a-37e0-45f5-b097-eaf2e7e2297c.jpeg',title:"Trek Name A", viewGallery:" view gallery"},];
  const slideLeft =()=>{
  var slider = document.getElementById("slider");
  slider.scrollLeft= slider.scrollLeft - 300;
  }
  const slideRight =()=>{
    var slider = document.getElementById("slider");
    slider.scrollLeft= slider.scrollLeft + 300;
    }

  return (
    <>
    <div className='featured'>
            <h1 className='featured-text'>Beauty of <span className='red'>Kuari Pass Trek</span></h1>
            <p className='featured-text'>The trek at an altitude of 12,516 ft. offers you summit views of Dronagiri, Chitrakantha, Chaukhamba, and Tali top. The Interlude of meadows, beautiful forests blooming with rhododendron, and oak trees make your path all the more scenic. While on the trek, you can also admire the mesmerizing views of holy rivers such as Bhagirathi and Alakananda which makes the ascent even more exciting.</p>
            <div className='container'>
                <img className='span-3 image-grid-row-2' src= 'https://user-images.githubusercontent.com/103182581/178497180-4ddb2254-f637-4339-8280-7d056409e090.jpeg' alt='' />
                <img src= 'https://user-images.githubusercontent.com/103182581/178497181-d3dc0b61-8562-4bab-8415-404141e218ca.jpeg' alt='' />
                <img src='https://user-images.githubusercontent.com/103182581/178497186-83892fde-3501-48b8-b47a-9bdbc77c666c.jpeg' alt='' />
                <img src='https://user-images.githubusercontent.com/103182581/178497191-c0b1d84c-7e79-49d1-b946-342e0e6320f6.jpeg' alt='' />
                <img src='https://user-images.githubusercontent.com/103182581/178497194-f5fe47c7-71ff-4d9f-81a7-08e4fa9c3065.jpeg' alt='' />
            </div>
    </div>

    <div id="body">

<div id="Slider-Container">

       <MdChevronLeft size={40} className="slider-icon left" onClick={slideLeft}/>
       <div id="slider">
          { slides.map((slide,index)=>{
            return(
                <div className="slider-card" key={index}>
                    <div className="slider-card-image" style={{backgroundImage : `url(${slide.image})`}}></div>

                </div>
            )
          })

          }
       </div>
       <MdChevronRight size={40} className="slider-icon right" onClick={slideRight}/>

      </div>

    </div>

  </>

  )
}

export default KauriPass;