import React from "react";
import "./Template.css";
import { MdChevronLeft,MdChevronRight} from 'react-icons/md';





const Sikkim = (props) => {
  const slides = [
    {image: 'https://user-images.githubusercontent.com/103182581/178491489-d1cc2cc2-9c09-45a0-a1be-470cff1d45de.jpeg',title:"Trek Name A", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178491499-da184a6b-3621-4d3a-ab0d-58c24e4f23b0.jpeg' ,title:"Trek Name A", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178491461-913b8e13-4d20-4b15-b5ef-4329e5451709.jpeg' ,title:"Trek Name A", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178491517-c362c4a4-84f7-459e-8311-8248447d2180.jpeg',title:"Trek Name B", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178491522-985202dd-a2ee-452a-bdbc-252883f96606.jpeg',title:"Trek Name C", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178491526-4171390e-03b8-415a-a784-645b7580f9e6.jpeg',title:"Trek Name D", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178491531-b158dd32-fe03-4b3a-9f7b-8a347f613100.jpeg',title:"Trek Name A", viewGallery:" view gallery"},
  ];
  const slideLeft =()=>{
  var slider = document.getElementById("slider");
  slider.scrollLeft= slider.scrollLeft - 300;
  }
  const slideRight =()=>{
    var slider = document.getElementById("slider");
    slider.scrollLeft= slider.scrollLeft + 300;
    }

  return (
    <>
    <div className='featured'>
            <h1 className='featured-text'>Beauty of <span className='red'>Sikkim Trek</span></h1>
            <p className='featured-text'>A part of the Eastern Himalaya, Sikkim is notable for its biodiversity, including alpine and subtropical climates, as well as being a host to Kangchenjunga, the highest peak in India and third highest on Earth.Sikkim is known for its beautiful scenery, green meadow, crystalline lakes and towering mountain ranges. This kingdom of mountains has a personality of its own, you will be lost in the captivating destinations in the state.</p>
            <div className='container'>
                <img className='span-3 image-grid-row-2' src= 'https://user-images.githubusercontent.com/103182581/178491510-4d207eac-c082-41d7-acc3-ab220b4ffa0a.jpeg' alt='' />
                <img src= 'https://user-images.githubusercontent.com/103182581/178491465-5b086344-e0ef-4d05-b2ed-2de8838a0287.jpeg' alt='' />
                <img src='https://user-images.githubusercontent.com/103182581/178491470-576e4e7f-821c-465e-9d03-9e314e1c279b.jpeg' alt='' />
                <img src='https://user-images.githubusercontent.com/103182581/178491471-e2706cfa-3606-4912-9414-95c00c7e7b30.jpeg' alt='' />
                <img src='https://user-images.githubusercontent.com/103182581/178491481-edb02276-1a23-44ca-9922-a8b47c6f6731.jpeg' alt='' />
            </div>
    </div>

    <div id="body">

<div id="Slider-Container">

       <MdChevronLeft size={40} className="slider-icon left" onClick={slideLeft}/>
       <div id="slider">
          { slides.map((slide,index)=>{
            return(
                <div className="slider-card" key={index}>
                    <div className="slider-card-image" style={{backgroundImage : `url(${slide.image})`}}></div>

                </div>
            )
          })

          }
       </div>
       <MdChevronRight size={40} className="slider-icon right" onClick={slideRight}/>

      </div>

    </div>

  </>

  )
}

export default Sikkim;