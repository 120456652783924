import React from "react";
import Slider from "react-slick";
import "./CycleTrip.css";
import Vector1 from "./img/v1.webp";
import Vector2 from "./img/v2.webp";
import ct1 from "./img/ct1.webp";
import ct3 from "./img/ct3.webp";
import ct4 from "./img/ct4.webp";
import ct5 from "./img/ct5.webp";
import ct6 from "./img/ct6.webp";
import ct7 from "./img/ct7.webp";
import ct8 from "./img/ct8.webp";
import ct9 from "./img/ct9.webp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FirstImg from "./img/1.webp";
import SecondImg from "./img/2.webp";
import ThirdImg from "./img/3.webp";
import FourthImg from "./img/4.webp";
import FifthImg from "./img/5.webp";
import SixthImg from "./img/6.webp";

export default function CycleTrip() {
  var settings = {
    accessibility: false,
    arrows: false,
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    centerPadding: "50px",
    useCSS: true,
    responsive: [
      {
        breakpoint: 4000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  var cycle_trip = {
    accessibility: false,
    arrows: false,
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    centerPadding: "50px",
    useCSS: true,
  };

  return (
    <main id="cycle-trip">
      <h1 className="accent-red">Cycle Trip & Virtual Cycle Trip</h1>

      <div className="top_text">
        <p>
          <span>T</span>echnology <span>Ad</span>venture <span>S</span>ociety
          brings freshers a chance to explore the IIT Kharagpur campus with
          their peers alongside cycling, camps and a bunch of fun activities.
        </p>
      </div>

      <h3 className="event_heading accent-red">Cycle Trip</h3>

      <div className="c1">
        <div className="vector">
          <img src={Vector1} alt="Cycle Trip" />
        </div>
        <div className="material">
          <p className="event_text">
            The annual Cycle trip organised for freshers presents them a golden
            opportunity to explore the enormous campus of IIT Kharagpur and the
            surrounding areas of Hijli Forest. The entire event is filled with
            games, camping and abundant fun activities with prizes to kickstart
            their journey in IIT Kharagpur and also make great friends along the
            way.
          </p>
        </div>
      </div>

      <div className="c1">
        <div className="c11">
          <p className="past_text">
            Get ready with your bicycles and don't forget to bring in extra
            energy because that's the only thing which you require for this
            trip. Time for Games, Music, Dance, Camping, and CYCLING!
            <br />
            <br />
            Technology Adventure Society presents its first event of the year,
            "Cycle Trip", a chance to explore the campus with us.
            <br />
            <br />
            Route Gymkhana -> Old building -> RDC -> Helipad -> Hijli forest
          </p>
        </div>
        <div className="c11" id="image_container_helper">
          <div className="event-description">
            <div className="event-img-container">
              <Slider {...cycle_trip}>
                <div className="event-card">
                  <img className="event-img" src={ct1} />
                </div>
                <div className="event-card">
                  <img className="event-img" src={ct3} />
                </div>
                <div className="event-card">
                  <img className="event-img" src={ct4} />
                </div>
                <div className="event-card">
                  <img className="event-img" src={ct5} />
                </div>
                <div className="event-card">
                  <img className="event-img" src={ct6} />
                </div>
                <div className="event-card">
                  <img className="event-img" src={ct7} />
                </div>
                <div className="event-card">
                  <img className="event-img" src={ct8} />
                </div>
                <div className="event-card">
                  <img className="event-img" src={ct9} />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>

      <h3 className="event_heading accent-red">Getting excited? Watch this!</h3>
      <div className="video_container" align="center">
        <iframe
          src="https://www.youtube.com/embed/qKvLQ1KUGpQ"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>

      <h3 className="event_heading accent-red vct_heading">
        Virtual Cycle Trip
      </h3>
      <div className="c2">
        <div className="material">
          <p className="event_text vct_text">
            The Covid Pandemic may have strayed our beloved freshers in their
            homes, however it will not stop them from exploring their beautiful
            campus.
          </p>
          <p className="event_text vct_text">
            Technology Adventure Society brings forth a chance to explore the
            IIT Kharagpur campus virtually. The participants are required to
            identify the famous landmarks in the campus within a stipulated time
            and the fastest ones are gratuitously awarded with cash rewards.
          </p>
        </div>
        <div className="vector" id="vct_vector">
          <img src={Vector2} alt="" />
        </div>
      </div>
      <div className="slider">
        <Slider {...settings}>
          <div className="slider_content_trail">
            <div className="title">Virtual Cycle Trip'22 !!</div>
            <div className="card_img">
              <img src={FirstImg} alt="image1" />
            </div>
          </div>
          <div className="slider_content_trail">
            <div className="title">Harry Potter Theme !!</div>
            <div className="card_img">
              <img src={SecondImg} alt="image2" />
            </div>
          </div>
          <div className="slider_content_trail">
            <div className="title">What's your House ??</div>
            <div className="card_img">
              <img src={ThirdImg} alt="image3" />
            </div>
          </div>
          <div className="slider_content_trail">
            <div className="title">17 Riddles !!</div>
            <div className="card_img">
              <img src={FourthImg} alt="image4" />
            </div>
          </div>
          <div className="slider_content_trail">
            <div className="title">Let's Go !!</div>
            <div className="card_img">
              <img src={FifthImg} alt="image5" />
            </div>
          </div>
          <div className="slider_content_trail">
            <div className="title">Voila !!</div>
            <div className="card_img">
              <img src={SixthImg} alt="image5" />
            </div>
          </div>
        </Slider>
      </div>
    </main>
  );
}
