import React from "react";
import "./PreviousGovernors.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { PREVIOUS_GOVERNORS } from '../team';
import { FOREVER_GOVERNORS } from '../team';

export default function PreviousGovernors() {
  return (
    <div id="prev-govs">
      {
        PREVIOUS_GOVERNORS.map((govs, i) => (
          <div key={i} className="prev-card">
            <h3 className="prev-card-title">{govs.tenure}</h3>
            <div className="prev-card-content">
              {
                govs.names.map((name) => (
                  <p>{name}</p>
                ))
              }
            </div>
          </div>
        ))
      }

      <div className="prev-card forever">
        <h2 className="prev-card-title accent-red">2009 - FOREVER</h2>
        {
          FOREVER_GOVERNORS.names.map((name) => (
            <p>{name}</p>
          ))
        }
      </div>
    </div>
  );
}
