import React, { useEffect } from "react";
import "./AdvWeekend.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Aos from "aos";
import "aos/dist/aos.css";

//Image imports
import a from "./img/1.webp";
// import b from "./img/2.webp"
// import c from "./img/3.webp"
import rough from "./img/Rough.webp";
import Arc1 from "./img/Archery1.webp";
import Arc2 from "./img/Archery2.webp";
import Arc3 from "./img/Archery3.webp";
import ATV1 from "./img/ATV-Biking1.webp";
import ATV2 from "./img/ATV-Biking2.webp";
import ATV3 from "./img/ATV-Biking3.webp";
import Bull1 from "./img/Bull-Ride1.webp";
import Bull2 from "./img/Bull-Ride2.webp";
import Bull3 from "./img/Bull-Ride3.webp";
import Fox1 from "./img/Flying Fox1.webp";
import Gal0 from "./img/paintball.webp";
import Gal1 from "./img/gal1.webp";
import Gal2 from "./img/gal2.webp";
import Gal3 from "./img/gal3.webp";
import Gal4 from "./img/paintball2.webp";
import Gal5 from "./img/gal5.webp";
import Gal6 from "./img/gal6.webp";
import Gal7 from "./img/gal7.webp";
import Go1 from "./img/Go-Karting1.webp";
import Go2 from "./img/Go-Karting2.webp";
import Go3 from "./img/Go-Karting3.webp";
import Foo1 from "./img/Human-Foosball1.webp";
import Foo2 from "./img/Human-Foosball2.webp";
import Foo3 from "./img/Human-Foosball3.webp";
import Tag1 from "./img/Laser-Tag1.webp";
import Tag2 from "./img/Laser-Tag2.webp";
import Tag3 from "./img/Laser-Tag3.webp";
import Melt1 from "./img/Meltdown1.webp";
import Melt2 from "./img/Meltdown2.webp";
import Melt3 from "./img/Meltdown3.webp";
import Paint1 from "./img/paintball1.webp";
import Paint3 from "./img/paintball3.webp";
import Paint4 from "./img/paintball4.webp";
import Shoot1 from "./img/Shooting1.webp";
import Shoot2 from "./img/Shooting2.webp";
import Sumo1 from "./img/Sumo-Wrestling1.webp";
import Sumo2 from "./img/Sumo-Wrestling2.webp";
import Tight1 from "./img/Tight-Rope Walking1.webp";
import Tight2 from "./img/Tight-Rope Walking2.webp";
import Wall1 from "./img/Wall-Climbing1.webp";
import Wall2 from "./img/Wall-Climbing2.webp";
import z1 from "./img/Zorbing1.webp";
import z2 from "./img/Zorbing2.webp";
import z3 from "./img/Zorbing3.webp";

const AdvWeekend = () => {
  // animation on scroll effect settings
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);

  // photo-gallery carousel settings
  var settings = {
    accessibility: false,
    arrows: false,
    dots: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    pauseOnHover: false,
    centerPadding: "50px",
    useCSS: true,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  //event-specific photo carousel settings
  var event_specific = {
    accessibility: false,
    arrows: false,
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    centerPadding: "50px",
    useCSS: true,
  };

  return (
    <main id="adventure-weekend">
      <div id="AdvWeekend-FirstSight">
        <h1>
          <span className="accent-red">
            <u>Adventure Weekend</u>
          </span>
          <br />
        </h1>
        <div>
          <h2>
            The Adventure Weekend is an event of two days full of craziness,
            adventurous activities,
            <br /> and fun-filled games.
            <br />
            It was named for its occurrence on weekends.
            <br /> We organize a lot of events like
            <span className="accent-red">
              {" "}
              Paintball, Laser Tag, Meltdown, Bull Ride, Zorbing, Archery, Human
              Foosball, GO Karting, ATV Biking,
            </span>{" "}
            and a lot more.
            <br />
            Some more events that occurred in the past are{" "}
            <span className="accent-red">
              Tight-Rope Walking, Wall-Climbing, Tarzan-Swing, Flying Fox
            </span>
            , etc.
            <br /> C'mon, let's dive deep into the experiences of the exciting
            events...
          </h2>
        </div>
        <br />
        <br />

        <div id="AdvWeekend-Video" data-aos="fade-up">
          <iframe
            width="100%"
            height="720"
            src="https://www.youtube.com/embed/ESNfPPuWdl4?rel=0?version=3&autoplay=1&controls=0&&showinfo=0&loop=1"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope"
          ></iframe>
        </div>
        <br />
        <br />
      </div>

      <div id="AdvWeekend-Quote" data-aos="fade-up">
        <h3>
          "There is a thin line between
          <br />
          <span className="accent-red Bold-u">Life and Death</span>
          <br />
          and we flirt with it"
        </h3>
      </div>

      <div id="AdvWeekend-ImgGallery" data-aos="fade-up">
        <Slider {...settings}>
          <div className="card">
            <img className="galleryimg" src={a} />
          </div>
          <div className="card">
            <img className="galleryimg" src={Gal0} />
          </div>
          <div className="card">
            <img className="galleryimg" src={Gal1} />
          </div>
          <div className="card">
            <img className="galleryimg" src={Gal2} />
          </div>
          <div className="card">
            <img className="galleryimg" src={Gal3} />
          </div>
          <div className="card">
            <img className="galleryimg" src={rough} />
          </div>
          <div className="card">
            <img className="galleryimg" src={Gal4} />
          </div>
          <div className="card">
            <img className="galleryimg" src={Gal5} />
          </div>
          <div className="card">
            <img className="galleryimg" src={Gal6} />
          </div>
          <div className="card">
            <img className="galleryimg" src={Gal7} />
          </div>
        </Slider>
      </div>
      <br />
      <hr />

      <div id="AdvWeekend-Events">
        <div id="Paintball" data-aos="fade-up">
          <h1>
            <span className="accent-red">Paintball</span>
            <br />
          </h1>
          <br />
          <br />
          <div className="event-description odd">
            <div id="Paintball-img" className="event-img-container">
              <Slider {...event_specific}>
                <div className="event-card">
                  <img className="event-img" src={Paint1} />
                </div>
                <div className="event-card">
                  <img className="event-img" src={Paint4} />
                </div>
                <div className="event-card">
                  <img className="event-img" src={Paint3} />
                </div>
              </Slider>
            </div>
            <div id="Paintball-content" className="event-content-container">
              <div className="smlscrn">
                How's the idea to play Holi in an off-season with color-filled
                balls? Isn't it making you curious?
                <br />
                Come out of your den and show-off your skills to dodge all
                color-balls and be the last-one uncoloured!!
              </div>
              <p className="aftrthreshold">
                How's the idea to play Holi in an off-season with color-filled
                balls? Isn't it making you curious?
                <br />
                Come out of your den and show-off your skills to dodge all
                color-balls and be the last-one uncoloured!!
              </p>
              <p className="bigscrn">
                Paintball is an extreme sport where players compete to eliminate
                opponents by tagging them with capsules containing water-soluble
                dye and gelatin shells outside (called paintballs) propelled
                from a device called a paintball marker (commonly known as a
                paintball gun).
              </p>
              <p className="mediumscrn">
                Paintballs are composed of a non-toxic, biodegradable,
                water-soluble polymer.
              </p>
            </div>
          </div>
        </div>
        <br />
        <hr />

        <div id="Meltdown" data-aos="fade-up">
          <h1>
            <span className="accent-red">Meltdown</span>
            <br />
          </h1>
          <br />
          <br />
          <div className="event-description even">
            <div id="Meltdown-content" className="event-content-container">
              <div className="smlscrn">
                Feel the thrill of Takeshi's Castle live with this event.
                <br />A perfect combo of timing observation and decision-making
                can make you the last lone-stranded winner.
              </div>
              <p className="mediumscrn">
                One game that is gaining its importance worldwide is the
                Meltdown game!
                <br />
                <br />
              </p>
              <p className="aftrthreshold">
                Feel the thrill of Takeshi's Castle live with this event.
                <br />A perfect combo of timing observation and decision-making
                can make you the last lone-stranded winner.
                <br />
                <br />
              </p>
              <p className="bigscrn">
                The game is challenging and funny as the person spins and falls
                multiple times. You may laugh at others falling, and in no time
                the soft rod will spin and hit you and everyone ends up laughing
                so hard.
              </p>
            </div>
            <div id="Meltdown-img" className="event-img-container">
              <Slider {...event_specific}>
                <div className="event-card">
                  <img className="event-img" src={Melt1} />
                </div>
                <div className="event-card">
                  <img className="event-img" src={Melt2} />
                </div>
                <div className="event-card">
                  <img className="event-img" src={Melt3} />
                </div>
              </Slider>
            </div>
          </div>
        </div>
        <br />
        <hr />

        <div id="KartMania" data-aos="fade-up">
          <h1>
            <span className="accent-red">KartMania</span>
            <br />
          </h1>
          <br />
          <div id="KartMania-content" className="event-content-container">
            <h2 id="Kart-Content">
              Even heard of the word "Mania"? It means a strong desire! Yeah,
              you understood it right... this event is made especially for
              "mad-for-car" people.
              <br />
              It's a collection of events like Go-Karting, ATV-Biking,
              Trail-Blazer that uses Ground Vehicles and All-Terrain-Vehicles
              (ATV) to compete among participants...
            </h2>
            <br />
            <div id="KartMania-Video" data-aos="fade-up">
              <iframe
                width="100%"
                height="720"
                src="https://www.youtube.com/embed/sQ05p3c-1YQ?rel=0?version=3&autoplay=0&mute=1&controls=1&&showinfo=0&loop=1"
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope"
              ></iframe>
            </div>
          </div>
          <div className="event-description odd" data-aos="flip-right">
            <div id="Go-Karting-img" className="event-img-container">
              <Slider {...event_specific}>
                <div className="event-card">
                  <img className="event-img" src={Go1} />
                </div>
                <div className="event-card">
                  <img className="event-img" src={Go2} />
                </div>
                <div className="event-card">
                  <img className="event-img" src={Go3} />
                </div>
              </Slider>
            </div>
            <div id="Go-Karting-content" className="event-content-container">
              <h1 className="sub-event">
                <span className="accent-red">Go-Karting</span>
                <br />
              </h1>
              <div className="smlscrn">
                <br />
                Are you a fan of Formula1 racing?
                <br />
                Ever wanted to drive an F1 racecar?
                <br />
                We are here to fulfill your dreams of driving a cool racing car.
              </div>
              <p className="aftrthreshold">
                <br />
                Are you a fan of Formula1 racing?
                <br />
                Ever wanted to drive an F1 racecar?
                <br />
                We are here to fulfill your dreams of driving a race car.
              </p>
              <p className="bigscrn">
                We build great tracks where you can show off your skills in
                drifting, burnout, or even you can compete in a timed trail with
                your friends!
              </p>
              <p className="mediumscrn">
                <br />
                Ready? Get set! and Go!!!
              </p>
            </div>
          </div>
          <div
            id="ATV-Biking"
            className="event-description even"
            data-aos="flip-left"
          >
            <div id="ATV-Biking-content" className="event-content-container">
              <h1 className="sub-event">
                <span className="accent-red">ATV-Biking</span>
                <br />
              </h1>
              <div className="smlscrn">
                <br />
                We also do care about bike enthusiasts...
                <br />
                so, along with race cars we have ATV Bikes to give you an
                experience of quad-biking and four-wheel fun.
                <br />
                Just board it and ride it... you don't need to think of the
                nature of the terrain at all.
              </div>
              <p className="aftrthreshold">
                <br />
                Also, we do care about bike enthusiasts...so along with race
                cars we have ATV Bikes to give you an experience of quad-biking
                and four-wheel fun.
                <br />
                Just board it and ride it... you don't need to think of the
                nature of the terrain at all.
              </p>
              <p className="bigscrn">
                <br />
                Whether it be sandy or rocky terrain, dry or a bit slippery,
                this car is gonna take you to your finish line.
              </p>
              <p className="mediumscrn">
                The only thing you need is control... the better it is, the
                faster you are...
              </p>
            </div>
            <div id="ATV-Biking-img" className="event-img-container">
              <Slider {...event_specific}>
                <div className="event-card">
                  <img className="event-img" src={ATV1} />
                </div>
                <div className="event-card">
                  <img className="event-img" src={ATV2} />
                </div>
                <div className="event-card">
                  <img className="event-img" src={ATV3} />
                </div>
              </Slider>
            </div>
          </div>
          {/* Lockdown-Affected Event */}
          {/* <div className="event-description" data-aos="flip-right">
            <div id="Trail-Blazer-img" className="event-img-container">
              <Slider {...event_specific}>
                <div className="event-card">
                  <img className="event-img" src={b}/>
                </div>
                <div className="event-card">
                  <img className="event-img" src={a}/>
                </div>
                <div className="event-card">
                  <img className="event-img" src={c}/>
                </div>
              </Slider>
            </div>
            <div id="Trail-Blazer-content" className="event-content-container">
              Trail-Blazer
            </div>
          </div>*/}
        </div>
        <br />
        <hr />

        <div id="Zorbing" data-aos="fade-up">
          <h1>
            <span className="accent-red">Zorbing</span>
            <br />
          </h1>
          <br />
          <br />
          <div className="event-description even">
            <div id="Zorbing-content" className="event-content-container">
              <div className="smlscrn">
                Ever thought of riding a bubble?
                <br />
                How would that be if we never had to be cautious while walking?
                <br />
                Well, try zorbing once and you will experience that fantasy
                sometimes come true...
              </div>
              <p className="aftrthreshold">
                Ever thought of riding a bubble?
                <br />
                How would that be if we never had to be cautious while walking?
                <br />
                Well, try zorbing once and you will experience that fantasy
                sometimes comes true...
              </p>
              <p className="bigscrn">
                <br />
                Bulky balls filled with air...
                <br />
                drop, fall, or collide, nothing to fear...
              </p>
              <p className="mediumscrn">
                <br />
                Just remember before a collision that Newton's third law is
                equally applicable here, too...
                <br />
                So, be ready for the recoil !
              </p>
            </div>
            <div id="Zorbing-img" className="event-img-container">
              <Slider {...event_specific}>
                <div className="event-card">
                  <img className="event-img" src={z1} />
                </div>
                <div className="event-card">
                  <img className="event-img" src={z2} />
                </div>
                <div className="event-card">
                  <img className="event-img" src={z3} />
                </div>
              </Slider>
            </div>
          </div>
        </div>
        <br />
        <hr />

        <div id="Human-Foosball" data-aos="flip-left">
          <h1>
            <span className="accent-red">Human-Foosball</span>
            <br />
          </h1>
          <br />
          <br />
          <div className="event-description odd">
            <div id="Human-Foosball-img" className="event-img-container">
              <Slider {...event_specific}>
                <div className="event-card">
                  <img className="event-img" src={Foo1} />
                </div>
                <div className="event-card">
                  <img className="event-img" src={Foo2} />
                </div>
                <div className="event-card">
                  <img className="event-img" src={Foo3} />
                </div>
              </Slider>
            </div>
            <div id="Human-Foosball-content">
              <div className="smlscrn">
                We got bored of playing Table Soccer and thought why not bring
                table soccer into the real world? And the result is right here
                in front of you.
                <br />
                Come and join us and see if you can score against us!
              </div>
              <p className="aftrthreshold">
                We got bored of playing Table Soccer and thought why not bring
                table soccer into the real world?
                <br />
                <br />
                And the result is right here in front of you.
              </p>
              <p className="bigscrn">
                <br />
                Humans have to play football in spite of being tied to a rope?!
                No, no, don't be afraid coz, this is NOT a punishment...
                <br />
                <br />
                Its a game... what you need is your concentration to pass the
                ball correctly to win as a team!
              </p>
              <p className="mediumscrn">
                <br />
                Come and join us and see if you can score against us!
              </p>
            </div>
          </div>
        </div>
        <br />
        <hr />

        <div id="Archery" data-aos="flip-right">
          <h1>
            <span className="accent-red">Archery</span>
            <br />
          </h1>
          <br />
          <br />
          <div className="event-description even">
            <div id="Archery-content" className="event-content-container">
              <div className="smlscrn">
                Ever dreamt of archery? Wanna play it? Obviously we cannot give
                "Sita" as the winning reward... but nice rewards are waiting for
                your perfect
                <br />
                "Hit-in-the-Center"
                <br />
                Come and test your archery skills!
              </div>
              <p className="aftrthreshold">
                Ever dreamt of archery? Wanna play it?
                <br />
                <br />
                Obviously we cannot give "Sita" as the winning reward... but
                nice rewards are waiting for your perfect "Hit-in-the-Center"
              </p>
              <p className="bigscrn">
                <br />
                No sharp tips involved! We use the safest rubber sucker-caps to
                stick the arrow to the board...
                <br />
                <br />
                Having some crazy thoughts of targeting a bald-head?
              </p>
              <p className="mediumscrn">
                <br />
                Test your archery skills now!
              </p>
            </div>
            <div id="Archery-img" className="event-img-container">
              <Slider {...event_specific}>
                <div className="event-card">
                  <img className="event-img" src={Arc1} />
                </div>
                <div className="event-card">
                  <img className="event-img" src={Arc2} />
                </div>
                <div className="event-card">
                  <img className="event-img" src={Arc3} />
                </div>
              </Slider>
            </div>
          </div>
        </div>
        <br />
        <hr />

        <div id="Laser-Tag" data-aos="flip-left">
          <h1>
            <span className="accent-red">Laser-Tag</span>
            <br />
          </h1>
          <br />
          <br />
          <div className="event-description odd">
            <div id="Laser-Tag-img" className="event-img-container">
              <Slider {...event_specific}>
                <div className="event-card">
                  <img className="event-img" src={Tag1} />
                </div>
                <div className="event-card">
                  <img className="event-img" src={Tag2} />
                </div>
                <div className="event-card">
                  <img className="event-img" src={Tag3} />
                </div>
              </Slider>
            </div>
            <div id="Laser-Tag-content">
              <div className="smlscrn">
                Were you out of luck in your hitting accuracy in Paintball?
                <br />
                Well, don't be upset, we have alternate options too... but now
                without any color palette.
                <br />
                Just "hit" your opponent with lasers from your laser-gun!
              </div>
              <p className="aftrthreshold">
                Were you out of luck in your hitting accuracy in Paintball?
                <br />
                <br />
                Well, don't be upset, we have alternate options too... but now
                without any color palette.
                <br />
                Just "hit" your opponent with lasers from your laser-gun!
              </p>
              <p className="bigscrn">
                <br />
                Our laser guns are completely safe and are devoid of harmful
                laser intensity. Don't be afraid of laser exposure... just dodge
                them to win...
              </p>
              <p className="mediumscrn">
                <br />
                What are you waiting for? Put your finger on the trigger and
                come along to the arena!
              </p>
            </div>
          </div>
        </div>
        <br />
        <hr />

        <div id="Bull-Ride" data-aos="flip-right">
          <h1>
            <span className="accent-red">Bull-Ride</span>
            <br />
          </h1>
          <br />
          <br />
          <div className="event-description even">
            <div id="Bull-Ride-content" className="event-content-container">
              <div className="smlscrn">
                How about a trip to Spain and enjoy the Bull Festival there? We
                know, that's quite risky...
                <br />
                so we called the bulls here and asked them to give us some fun,
                but with complete safety.
                <br />
                Enjoy the ride!
              </div>
              <p className="aftrthreshold">
                How about a trip to Spain and enjoy the <br />
                Bull Festival there?
                <br />
                <br />
                We know, that's quite risky...
                <br />
                so we called the bulls here and asked them to give us some fun,
                but with complete safety.
              </p>
              <p className="bigscrn">
                <br />
                Some minutes of leap, plunge and spin with a human on its
                back... and the bull calms down... your job is to stay on its
                back till the end...
                <br />
                seems quite exciting na?
              </p>
              <p className="mediumscrn">
                <br />
                Come and enjoy the ride!
              </p>
            </div>
            <div id="Bull-Ride-img" className="event-img-container">
              <Slider {...event_specific}>
                <div className="event-card">
                  <img className="event-img" src={Bull1} />
                </div>
                <div className="event-card">
                  <img className="event-img" src={Bull2} />
                </div>
                <div className="event-card">
                  <img className="event-img" src={Bull3} />
                </div>
              </Slider>
            </div>
          </div>
        </div>
        <br />
        <hr />
        <br />
        <hr />

        <div id="AdvWeekend-ImgGallery" data-aos="fade-down">
          <h1>
            <span className="accent-red past">Past-Events</span>
            <br />
          </h1>
          <br />
          <br />
          <Slider {...settings}>
            <div className="card">
              <img className="galleryimg" src={Tight1} />
              <br />
              <p>Tight-Rope Walking-2014</p>
            </div>
            <div className="card">
              <img className="galleryimg" src={Wall1} />
              <br />
              <p>Wall-Climbing-2012</p>
            </div>
            {/* <div className="card">
              <img className="galleryimg" src={c}/>
              <br/>
              <p>Tarzan-Swing</p>
            </div> */}
            <div className="card">
              <img className="galleryimg" src={Fox1} />
              <br />
              <p>Flying-Fox-2015</p>
            </div>
            <div className="card">
              <img className="galleryimg" src={Sumo1} />
              <br />
              <p>Sumo-Wrestling-2016</p>
            </div>
            <div className="card">
              <img className="galleryimg" src={Shoot1} />
              <br />
              <p>Shooting-2015</p>
            </div>
            <div className="card">
              <img className="galleryimg" src={Tight2} />
              <br />
              <p>Tight-Rope Walking-2016</p>
            </div>
            <div className="card">
              <img className="galleryimg" src={Wall2} />
              <br />
              <p>Wall-Climbing-2015</p>
            </div>
            <div className="card">
              <img className="galleryimg" src={Sumo2} />
              <br />
              <p>Sumo-Wrestling-2016</p>
            </div>
            <div className="card">
              <img className="galleryimg" src={Shoot2} />
              <br />
              <p>Shooting-2015</p>
            </div>
          </Slider>
        </div>
        <br />
        {/* <br/>
        <br/> */}
      </div>
    </main>
  );
};

export default AdvWeekend;
