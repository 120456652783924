import React from "react";
import "./Template.css";
import { MdChevronLeft,MdChevronRight} from 'react-icons/md';




const Roopkund = (props) => {
  const slides = [{image: 'https://user-images.githubusercontent.com/103182581/178455219-afda8eae-2db1-4668-850f-0a90d4685d66.jpeg',title:"Trek Name A", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178455224-5d05fa29-cdd8-4115-baba-fb0c49c3bc9c.jpeg' ,title:"Trek Name A", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178455229-3e230924-dfb1-4ef8-a03f-2813e71d4174.jpeg' ,title:"Trek Name A", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178455241-3831293e-37fc-4a9b-87a6-4ff9edcd4c96.jpeg',title:"Trek Name B", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178455252-25515187-c923-4d1b-a265-7495eaa0f67d.jpeg',title:"Trek Name C", viewGallery:" view gallery"},
  {image: 'https://user-images.githubusercontent.com/103182581/178455255-af7dc993-9583-4b31-afaa-31abd24ce59b.jpeg',title:"Trek Name D", viewGallery:" view gallery"},
  ];
  const slideLeft =()=>{
  var slider = document.getElementById("slider");
  slider.scrollLeft= slider.scrollLeft - 300;
  }
  const slideRight =()=>{
    var slider = document.getElementById("slider");
    slider.scrollLeft= slider.scrollLeft + 300;
    }

  return (
    <>
    <div className='featured'>
            <h1 className='featured-text'>Beauty of <span className='red'>Roopkund Trek</span></h1>
            <p className='featured-text'>Roopkund is a beautiful trek with heart-filling views throughout the trek. The travel from forests to meadows to rocky areas to snow in a matter of 4 days with landscape changing every day makes it a dish to eyes.The trek passes through scenic sites such as Lohagunj Pass, Bedni River,Dinda Village, Ali Bugyal and many more...</p>
            <div className='container'>
                <img className='span-3 image-grid-row-2' src= 'https://user-images.githubusercontent.com/103182581/178455182-f98e1278-de55-409a-8551-b30a58067353.jpeg' alt='' />
                <img src= 'https://user-images.githubusercontent.com/103182581/178455187-bc58d5b7-764c-4478-9df5-0853494e0a62.jpeg' alt='' />
                <img src='https://user-images.githubusercontent.com/103182581/178455191-1d980660-5926-49ce-aa91-8920c4032235.jpeg' alt='' />
                <img src='https://user-images.githubusercontent.com/103182581/178455200-d42d047b-8b41-4f37-b909-d73d09ace78a.jpeg' alt='' />
                <img src='https://user-images.githubusercontent.com/103182581/178455209-f2ed634a-e838-463a-a873-0e7123c3fd05.jpeg' alt='' />
            </div>
    </div>

    <div id="body">

<div id="Slider-Container">

       <MdChevronLeft size={40} className="slider-icon left" onClick={slideLeft}/>
       <div id="slider">
          { slides.map((slide,index)=>{
            return(
                <div className="slider-card" key={index}>
                    <div className="slider-card-image" style={{backgroundImage : `url(${slide.image})`}}></div>

                </div>
            )
          })

          }
       </div>
       <MdChevronRight size={40} className="slider-icon right" onClick={slideRight}/>

      </div>

    </div>

  </>

  )
}

export default Roopkund;